
.cont {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.cont__left {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cont__right {
    width: 35%;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    flex-direction: column;
    overflow-y: auto;
    scrollbar-width: none;
}

.cont__left > img {
    border-radius: 5px;
    height: 99%;
    min-width: 100%; 
}

@media (max-width: 1768px) {
    .cont {
        align-items: center;
    }

    .cont__right {
        width: 40%;
    }
}

@media (max-width: 1568px) {
    .cont {
        align-items: center;
    }

    .cont__right {
        width: 45%;
    }
}

@media (max-width: 1400px) {
    .cont {
        align-items: center;
    }

    .cont__right {
        width: 48%;
    }
}

@media (max-width: 1300px) {
    .cont {
        align-items: center;
    }

    .cont__right {
        width: 52%;
    }
}

@media (max-width: 1200px) {
    .cont {
        flex-direction: column; 
        align-items: center;
        height: auto;
    }

    .cont__left {
        width: 100%;
        height: auto;
    }

    .cont__right {
        width: 95%;
        height: 100%;
        margin-left: 0;
    }

    .cont__left > img {
        border-radius: 5px;
        width: 100%; 
        height: auto;
    }
}