.policyContainer {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 60px;
}

.headerSection {
    background-image: url('../../../public/Assets/Modules/Home/FAQ/faqback.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    background-color: #061244;
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 100px;
    text-align: center;
}

 .childContent {
     padding: 20px 400px;
 }

.headerContent {
    display: flex;
    width: 692px;
    max-width: 100%;
    flex-direction: column;
    justify-content: start;
}

.headerTextWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.categoryLabel {
    color: rgba(191, 191, 191, 1);
    font-size: 24px;
    line-height: 52px;
    letter-spacing: 0.48px;
}

.mainTitle {
    color: rgba(255, 255, 255, 1);
    font-size: 36px;
    font-weight: 420;
    line-height: 1;
    align-self: stretch;
    margin-top: 6px;
}

.subtitle {
    color: rgba(215, 215, 215, 1);
    font-size: 20px;
    line-height: 38px;
    margin-top: 6px;
}

.policyContent {
    align-self: center;
    display: flex;
    margin-top: 62px;
    width: 100%;
    max-width: 1368px;
    gap: 40px 54px;
    flex-wrap: wrap;
    padding: 20px;
}

.policyText {
    color: rgba(0, 0, 0, 1);
    align-self: start;
    flex-grow: 1;
    width: 1293px;
    flex-basis: auto;
    font: 400 18px/28px SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif;
}

.scrollBarContainer {
    display: flex;
    gap: 10px;
    justify-content: start;
    padding: 4px;
}

.scrollBarWrapper {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    flex-direction: column;
    flex: 1;
    flex-basis: 0%;
}

.scrollBarTrack {
    border-radius: 8px;
    background-color: rgba(18, 37, 119, 1);
    display: flex;
    height: 137px;
}

.textBold {
    font-family: Sequel Sans, sans-serif;
    font-weight: 600;
    font-size: 20px;
}

.textRegular {
    font-family: Sequel Sans, sans-serif;
    font-weight: 310;
}

.textLight {
    font-family: Sequel Sans, sans-serif;
    font-weight: 310;
    font-size: 16px;
}

@media (max-width: 991px) {
    .policyContainer {
        margin-top: 80px;
    }

    .childContent {
        padding: 20px;
    }

    .headerSection {
        max-width: 100%;
        padding: 0 20px;
    }

    .headerTextWrapper {
        max-width: 100%;
    }

    .categoryLabel {
        max-width: 100%;
    }

    .mainTitle {
        max-width: 100%;
    }

    .subtitle {
        max-width: 100%;
    }

    .policyContent {
        max-width: 100%;
        margin-top: 40px;
    }

    .policyText {
        max-width: 100%;
    }

    .scrollBarContainer {
        display: none;
    }

    .scrollBarWrapper {
        padding-bottom: 100px;
    }
}