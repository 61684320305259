.successSection {
  border-radius: 24px;
  background: #fff;
  display: flex;
  max-width: 652px;
  flex-direction: column;
  overflow: hidden;
  font-family: Sequel Sans, sans-serif;
  justify-content: center;
  padding: 45px 40px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.contentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: start;
}


.successIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 68px;
  border-radius: 0;
  align-self: center;
}

.textContent {
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

.title {
  color: #0a0a0a;
  font-size: 24px;
  font-weight: 420;
  line-height: 1;
}

.description {
  color: #666;
  font-size: 18px;
  font-weight: 405;
  line-height: 32px;
  margin-top: 16px;
}

@media (max-width: 991px) {
  /* .successSection {
    padding: 0 20px;
  } */

  .container {
    max-width: 100%;
  }

  .title {
    max-width: 100%;
  }

  .description {
    max-width: 100%;
  }

  .buttonWrapper {
    margin-top: 40px;
  }

  .textContent {
    max-width: 100%;
  }

  .contentWrapper {
    max-width: 100%;
  }
}

.buttonWrapper {
  align-self: center;
  display: flex;
  margin-top: 42px;
  width: 288px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
}

.dashboardButton {
  align-self: stretch;
  border-radius: 100px;
  background-color: rgb(18, 37, 119);
  min-height: 48px;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 16px;
  font-weight: 415;
  letter-spacing: 0.48px;
  padding: 12px 16px;
  cursor: pointer;
}