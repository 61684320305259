.addressContainer {
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  margin: 0 auto;
  padding: 5px;
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.addressContainer::-webkit-scrollbar {
  display: none;
}

.formWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 20px;
}

.formContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Sequel Sans, sans-serif;
  justify-content: start;
}

.formSection {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  margin-top: 24px;
}

.sectionTitle {
  color: rgba(18, 37, 119, 1);
  font: 420 16px/1 Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.currentAddressSection {
  margin-top: 20px;
}

.required {
  color: rgba(220, 38, 38, 1);
}

.inputGroup {
  display: flex;
  margin-top: 12px;
  width: 100%;
  flex-direction: column;
  font-family: Sequel Sans, sans-serif;
  font-weight: 405;
  justify-content: start;
}

.inputLabel {
  color: rgba(4, 27, 45, 1);
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.28px;
}

.inputField {
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eee;
  background: var(--White, #fff);
  display: flex;
  margin-top: 6px;
  width: 100%;
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  color: rgba(143, 146, 148, 1);
  letter-spacing: 0.32px;
  padding: 10px 14px;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  justify-content: start;
  height: 100%;
  width: 48px;
  padding: 10px 14px;
}

.dropdownIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}

.checkboxContainer {
  display: flex;
  margin-top: 12px;
  min-height: 24px;
  width: 414px;
  max-width: 100%;
  align-items: center;
  gap: 8px;
}

.checkbox {
  border-radius: 6px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  width: 24px;
  margin: auto 0;
  padding: 2px;
}

.checkboxInput {
  border-radius: 4px;
  border: 1.2px solid var(--Greys-300, #ccc);
  background: var(--Greys-100, #fff);
  width: 20px;
  height: 20px;
}

.checkboxLabel {
  color: rgba(102, 102, 102, 1);
  align-self: stretch;
  width: 401px;
  margin: auto 0;
  font: 400 14px/1.6 SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.errorText {
    color: #ff0000;
    font-size: 0.8rem;
    margin-top: 0.2rem;
}

.inputError {
    border-color: #ff0000;
}