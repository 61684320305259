.container {
    display: flex;
    flex-direction: column;
    font-family: Sequel Sans, sans-serif;
    margin: 40px 80px;
    max-width: 1440px;
    margin-inline: auto;
}

.content {
    display: flex;
    width: 100%;
    gap: 60px;
    margin-bottom: 60px;
}

.header {
    flex-shrink: 0;
    width: 329px;
}

.label {
    color: rgba(18, 37, 119, 1);
    font-size: 18px;
    font-weight: 415;
    line-height: 1;
    letter-spacing: 0.36px;
    display: block;
    margin-bottom: 16px;
}

.listStyle{
    list-style: none;
}

.title {
    color: rgba(20, 24, 62, 1);
    font-size: 36px;
    font-weight: 420;
    line-height: 1.2;
    letter-spacing: -0.36px;
    margin: 0 0 16px 0;
}

.description {
    color: rgba(167, 167, 167, 0.96);
    font-size: 20px;
    font-weight: 410;
    line-height: 1.5;
    margin: 0;
}

.cardsContainer {
    flex-grow: 1;
}

.cardsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 24px;
    width: 100%;
}

.card {
    border: none;
    border-radius: 12px;
    background-color: rgba(242, 244, 255, 1);
    padding: 24px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: left;
}

.card:hover,
.card:focus-visible {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(18, 37, 119, 0.5);
}

.cardIcon {
    width: 35px;
    height: 35px;
    border-radius: 18px;
    margin-bottom: 15px;
}

.cardTitle {
    color: rgba(18, 37, 119, 1);
    font-size: 16px;
    font-weight: 415;
    line-height: 1.2;
    margin: 8px 0 8px 0;
}

.cardDescription {
    color: rgba(10, 10, 10, 1);
    font-size: 14px;
    font-weight: 405;
    line-height: 1.4;
    letter-spacing: 0.42px;
    margin: 0;
}

.banner {
    /* background-color: rgba(18, 37, 119, 1);
    border-radius: 16px;
    padding: 48px;
    margin-top: 40px; */
        display: flex;
        flex-direction: column;
        position: relative;
        min-height: auto;
        width: 100%;
        justify-content: center;
        padding: 48px;
        margin-top: 40px;
        background-color: #1a365d;
        background-image: url('../../../public/Assets/Modules/Home/FAQ/faqbottom.svg');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 1rem;
        box-sizing: border-box;
        max-width: 100%;
        overflow: hidden;
}

.bannerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 100%;
    margin: 0 auto;
}

.bannerImage {
    width: 100%;
    height: 80px;
    border-radius: 60px;
    margin-bottom: 24px;
    object-fit: contain;
    background-color: transparent;
}

.bannerTitle {
    color: white;
    font-size: 28px;
    font-weight: 420;
    margin: 0 0 16px 0;
}

.bannerDescription {
    color: rgba(255, 255, 255, 0.9);
    font-size: 18px;
    line-height: 1.5;
    margin: 0 0 24px 0;
}

.getInTouchButton {
    background-color: white;
    color: rgba(18, 37, 119, 1);
    border: none;
    border-radius: 100px;
    padding: 16px 32px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.getInTouchButton:hover,
.getInTouchButton:focus-visible {
    background-color: rgba(242, 244, 255, 1);
}

@media (max-width: 1200px) {
    .container {
        margin: 40px;
    }

    .content {
        flex-direction: column;
        gap: 40px;
    }

    .header {
        width: 100%;
        max-width: 600px;
    }
}

@media (max-width: 768px) {
    .container {
        margin: 20px;
    }

    .banner {
        padding: 32px 20px;
    }

    .cardsGrid {
        grid-template-columns: 1fr;
    }
        .banner {
            padding: 24px;
            background-size: contain;
            max-width: 90%;
        }
}

@media (prefers-reduced-motion: reduce) {

    .card,
    .getInTouchButton {
        transition: none;
    }
}