.moreDetailBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 7px 0px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  min-width: 11rem;
}

@media (min-width: 1500px) {
  .moreDetailBtn {
    font-size: 1.5rem;
    padding: 10px 20px;
  }
}

@media (max-width: 1260px) {
  .moreDetailBtn {
    font-size: 0.8rem;
    min-width: 9rem;
  }
}

@media (max-width: 768px) {
  .moreDetailBtn {
    font-size: 1.2rem;
    height: 45px;
    padding: 22px 20px;
  }
}

@media (max-width: 550px) {
  .moreDetailBtn {
    height: 20px;
    font-size: 14px;
  }

  .moreDetailIcon {
    display: none;
  }
}
