.section_top2,
.section_top4,
.section_top8 {
  background: linear-gradient(
      0deg,
      rgba(22, 93, 201, 0.2) 0%,
      rgba(22, 93, 201, 0.2) 100%
    ),
    #f8f9ff;
}

.section_heading_color {
  color: #00183D;
}

.section_top8_img{
  margin-top: -2rem;
}

@media (max-width: 576px) {
  .section_top2 {
    margin-top: 0rem;
  }

  .section_top8 {
    /* margin */
    padding-top: 40px;
    margin-bottom: 0rem;
  }
}
