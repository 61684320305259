.optionButton {
  border-radius: 12px;
  display: flex;
  width: 100%;
  align-items: start;
  gap: 8px;
  overflow: hidden;
  justify-content: start;
  padding: 16px;
  border: 2px solid rgba(234, 234, 234, 1);
  margin-top: 24px;
  cursor: pointer;
  background-color: #fff;
}
.optionButton:first-child {
  margin-top: 0;
  border-color: rgba(18, 37, 119, 1);
}
.checkbox {
  display: flex;
  min-width: 240px;
  width: 100%;
  align-items: start;
  gap: 12px;
  justify-content: start;
  flex: 1;
}
.checkboxWrapper {
  display: flex;
  padding-top: 2px;
  align-items: center;
  justify-content: center;
  width: 16px;
}
.checkboxBase {
  fill: var(--Brand-50, #f9f5ff);
  stroke-width: 1px;
  stroke: #122577;
  display: flex;
  width: 16px;
  height: 16px;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  padding: 3px 2px;
  border: 1px solid rgba(18, 37, 119, 1);
  border-radius: 50%;
}
.checkboxBaseUnselected {
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  min-height: 16px;
  width: 16px;
  height: 16px;
}
.check {
  background-color: rgba(18, 37, 119, 1);
  border-radius: 50%;
  display: flex;
  width: 100%;
  height: 10px;
}
.content {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  font-family: Sequel Sans, sans-serif;
  justify-content: start;
  flex: 1;
}
.text {
  color: var(--Gray-700, #344054);
  font-size: 16px;
  font-weight:600;
  line-height: 1;
  letter-spacing: 0.48px;
  text-align: start;
}
.supportingText {
  color: rgba(136, 136, 136, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.42px;
  margin-top: 4px;
}
