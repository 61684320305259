.center{
        display: flex;
        align-items: center;
        justify-content: center;
}

.space-between{
        display: flex;
        align-items: center;
        justify-content: space-between;
}

.center-vertical{
        display: flex;
        align-items: center;
}