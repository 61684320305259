.datePickerWrapper {
  position: relative;
  width: 100%;
}

.datePicker {
  width: 100%;
  padding: 10px 14px;
  border: 1px solid #eee;
  border-radius: 8px;
  font-size: 16px;
  color: rgba(4, 27, 45, 1);
  background-color: #fff;
  appearance: none;
  cursor: pointer;
}

.datePicker::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.calendarIcon {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.datePicker:focus {
  outline: none;
  border-color: rgba(79, 70, 229, 1);
}

.datePicker:hover {
  border-color: rgba(79, 70, 229, 0.5);
}

.imageStyle{ 
    position: absolute;
    right: 14px;
    top: 14px;
    width: 24px;
    height:  24px;
}