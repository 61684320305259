.blogDetails {
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 20px;
    margin-top: 60px;
}

.container {
    align-self: center;
    display: flex;
    margin-top: 85px;
    width: 100%;
    max-width: 1216px;
    flex-direction: column;
    align-items: center;
}

.blogHeader {
    width: 100%;
    max-width: 1026px;
}

.authorInfo {
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: Sequel Sans, sans-serif;
    line-height: 40px;
}

.authorDetails {
    display: flex;
    align-items: center;
    gap: 12px;
}

.authorAvatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
}

.authorName {
    font-size: 18px;
    color: #121416;
    font-weight: 410;
}

.readMoreButton {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(18, 37, 119, 1);
    font: 415 16px 'Sequel Sans', sans-serif;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: color 0.2s ease;
    text-decoration: underline;
}

.readMoreButton:hover {
    color: rgba(18, 37, 119, 0.8);
    transform: scale(1.05);
    box-shadow: none;
}

.readMoreButton:hover{
    transform: translateX(4px);
}

.postDate {
    color: #6c757d;
    font-size: 14px;
    font-weight: 400;
}

.blogTitle {
    color: #333;
    letter-spacing: 0.48px;
    margin: 24px 0 0;
    font: 425 48px/64px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.mainImage {
    width: 100%;
    border-radius: 8px;
    margin-top: 46px;
    object-fit: cover;
    aspect-ratio: 2;
}

.blogContent {
    width: 100%;
    max-width: 1026px;
    margin-top: 42px;
}

.introduction,
.benefitText,
.conclusion {
    color: #3f3d3d;
    letter-spacing: 0.18px;
    font: 405 18px/27px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
    margin-bottom: 28px;
}

.benefitsSection {
    margin: 40px 0;
}

.benefitTitle {
    color: #333;
    font: 420 20px/1.4 Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
    margin: 32px 0 16px;
    font-weight: 600;
}

.benefitsList {
    list-style-type: disc;
    margin-left: 20px;
    color: #3f3d3d;
    font: 405 18px/27px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.benefitsList li {
    margin-bottom: 12px;
}

.quote {
    display: flex;
    margin: 40px 0;
    gap: 19px;
    color: #666;
}

.quoteBar {
    background-color: #0026c9;
    width: 5px;
    height: 104px;
    flex-shrink: 0;
}

.quoteText {
    font: 412 24px/40px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
    flex-grow: 1;
}

.quoteAuthor {
    color: #333;
    letter-spacing: 0.16px;
    margin: 4px 0 0 170px;
    font: 425 16px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.contentImage {
    width: 100%;
    border-radius: 8px;
    margin: 40px 0;
    aspect-ratio: 2.62;
    object-fit: cover;
}

.relatedPosts {
    width: 100%;
    margin-top: 75px;
}

.relatedHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.relatedTitle {
    color: #101828;
    font-size: 26px;
    font-weight: 420;
}

.viewAllLink {
    color: #122577;
    font-size: 16px;
    font-weight: 415;
    letter-spacing: 0.32px;
    text-decoration: none;
}

.relatedGrid {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
}

.blogCard {
    flex: 1;
    min-width: 240px;
    display: flex;
    flex-direction: column;
}

.cardImage {
    width: 100%;
    border-radius: 4px;
    aspect-ratio: 1.8;
    object-fit: cover;
}

.cardContent {
    margin-top: 24px;
}

.postTitle {
    color: #101828;
    font-size: 18px;
    font-weight: 420;
    line-height: 29px;
    letter-spacing: 0.18px;
    margin: 12px 0 6px;
}

.postDescription {
    color: #475467;
    font-size: 16px;
    font-weight: 405;
    line-height: 24px;
    letter-spacing: 0.32px;
}

.readMoreLink {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 12px;
}

.readMoreText {
    color: #122577;
    letter-spacing: 0.32px;
    font: 415 16px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.readMoreIcon {
    width: 24px;
    height: 24px;
}

@media (max-width: 991px) {
    .container {
        max-width: 100%;
        margin-top: 40px;
        padding: 0 20px;
    }

    .blogTitle {
        font-size: 40px;
        line-height: 60px;
    }

    .mainImage {
        margin-top: 40px;
    }

    .quoteAuthor {
        margin-left: 10px;
    }

    .relatedPosts {
        margin-top: 40px;
    }

    .blogCard {
        flex-basis: 100%;
    }
}

@media (prefers-reduced-motion: reduce) {
    .readMoreLink {
        transition: none;
    }
}

@media screen and (forced-colors: active) {
    .quoteBar {
        background-color: CanvasText;
    }

    .viewAllLink {
        color: LinkText;
    }
}