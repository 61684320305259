.servicesSection {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 935px;
  width: 100%;
  justify-content: center;
  padding: 89px 80px;
  background-color: #1a365d;
  background-image: url('../../../public/Assets/Modules/Home/servicesback.svg');
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
}

.contentWrapper {
  position: relative;
  z-index: 1;
}

.headerContent {
  text-align: center;
  margin-bottom: 56px;
}

.mainTitle {
  color: #FFFFFF;
  font-size: 36px;
  font-weight: 415;
  line-height: 1.2;
  margin-bottom: 16px;
}

.subtitle {
  font-size: 20px;
  line-height: 1.5;
}

.subtitleHighlight {
  color: #FFFFFF;
  font-weight: 410;
}

.subtitleSecondary {
  color: rgba(171, 171, 171, 1);
  font-weight: 410;
}

.tabsContainer {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding: 0 20px;
  margin: 0 -20px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tabsContainer::-webkit-scrollbar {
  display: none;
}

.tab {
  padding: 14px 30px;
  border-radius: 100px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: fit-content;
  border: 1px solid rgba(255, 255, 255, 0.67);
  color: rgba(255, 255, 255, 0.67);
  background-color: transparent;
}

.tab.active {
  background-color: #FFFFFF;
  color: rgba(37, 25, 65, 1);
  border-color: #FFFFFF;
}

.contentArea {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  margin-top: 24px;
}

.serviceInfo {
  display: flex;
  gap: 24px;
}

.serviceImage {
  width: 360px;
  height: 494px;
  object-fit: cover;
  border-radius: 4px;
}

.serviceDetails {
  flex: 1;
  align-self: flex-end;
  min-width: 300px;
}

.sectorTitle {
  font-size: 24px;
  color: #1E1E1E;
  margin-bottom: 12px;
  font-weight: 600;
}

.description {
  color: #5A5A5A;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.categoryTabs {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #fff;
  /* margin-bottom: 20px; */
}

.categoryTab {
  padding: 14px 0;
  color: #757575;
  cursor: pointer;
  min-width: 100px;
  text-align: center;
  border: none;
  background-color: #fff;
}

.categoryTab:hover {
  box-shadow: none;
}

.categoryTab.active {
  color: #213CB2;
  border-bottom: 2px solid #213CB2;
  font-weight: bold
}

.professionalsContainer {
  position: relative;
  padding: 20px 18px 10px;
  background: rgba(236, 240, 255, 1);
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
}

.professionalsGrid {
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* transition: transform 0.3s ease; */
  scroll-behavior: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  cursor: grab;
}

.professionalsGrid::-webkit-scrollbar {
  display: none;
}

.professionalCard {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  transition: transform 0.2s ease;
  flex: 0 0 20%;
  margin-right: 16px;
  user-select: none;
  list-style: none;
}

.professionalsGrid:active {
  cursor: grabbing;
}

.professionalCard:hover {
  transform: translateY(-2px);
}

.cardIcon {
  width: 32px;
  height: 32px;
  margin-bottom: 16px;
}

.cardTitle {
  color: #0D0D0D;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
}

.navigationControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.showingCount {
  color: #ABABAB;
  font-size: 10px;
}

.navigationButtons {
  display: flex;
  gap: 8px;
}

.navButton {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
}

.navButton img {
  width: 24px;
  height: 24px;
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 991px) {
  .servicesSection {
    padding: 40px 20px;
  }

  .serviceDetails {
    width: 100%;
  }

  .mainTitle {
    font-size: 28px;
  }

  .serviceInfo {
    flex-direction: column;
  }

  .serviceImage {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 640px) {
  .mainTitle {
    font-size: 20px;
  }

  .subtitle {
    font-size: 16px;
  }

  .tab {
    padding: 10px 20px;
  }
}