.container {
  display: flex;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  color: rgba(18, 37, 119, 1);
  font: 420 16px/1 Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 0 0 24px 0;
}

.imageUploader {
  position: relative;
  width: 80px;
  margin: 12px 0;
}

.uploadButton {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: none;
  background-color: rgba(233, 237, 255, 1);
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.uploadButton:hover {
  background-color: rgba(213, 217, 235, 1);
}

.uploadButton:focus {
  outline: 2px solid rgba(79, 70, 229, 1);
  outline-offset: 2px;
}

.placeholder {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cameraIcon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.profileImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.fileInput {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.spinner {
  width: 24px;
  height: 24px;
  border: 3px solid rgba(79, 70, 229, 0.3);
  border-radius: 50%;
  border-top-color: rgba(79, 70, 229, 1);
  animation: spin 1s linear infinite;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.error {
  color: #dc3545;
  font-size: 14px;
  margin-top: 5px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.label {
  color: rgba(4, 27, 45, 1);
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.28px;
  font-family: Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.required {
  color: rgba(220, 38, 38, 1);
  margin-left: 2px;
}

.input, .select {
  border-radius: 8px;
  border: 1px solid #eee;
  background-color: #fff;
  padding: 10px 14px;
  font-size: 16px;
  color: rgba(4, 27, 45, 1);
  width: 100%;
  transition: border-color 0.2s ease;
}

.input:focus, .select:focus {
  outline: none;
  border-color: rgba(79, 70, 229, 1);
}

.selectWrapper {
  position: relative;
}

.selectWrapper::after {
  content: '';
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  /* background-image: url('/icons/chevron-down.svg'); */
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
}

.phoneInput {
  display: flex;
  border-radius: 8px;
  border: 1px solid #eee;
  background-color: #fff;
  overflow: hidden;
}

.countryCode {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px 12px;
  border-right: 1px solid #eee;
  background-color: #fafafa;
}

.flag {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.chevron {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.phoneNumber {
  flex: 1;
  border: none;
  padding: 10px 14px;
  font-size: 16px;
  color: rgba(4, 27, 45, 1);
}

.phoneNumber:focus {
  outline: none;
}

.textarea {
  border-radius: 8px;
  border: 1px solid #eee;
  background-color: #fff;
  padding: 10px 14px;
  font-size: 16px;
  color: rgba(4, 27, 45, 1);
  width: 100%;
  min-height: 120px;
  resize: vertical;
  font-family: inherit;
  line-height: 1.5;
  transition: border-color 0.2s ease;
}

.textarea:focus {
  outline: none;
  border-color: rgba(79, 70, 229, 1);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}