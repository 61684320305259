.section_top2,
.section_top4,
.section_top8 {
  background: linear-gradient(
      180deg,
      rgba(255, 181, 177, 0.2) 0%,
      rgba(205, 126, 217, 0.2) 100%
    ),
    #f8f9ff;
}

.section_heading_color {
  color: #27001B;
}

@media (max-width: 768px) {
  .section_top3 h3 {
    font-size: 22px;
  }
}

@media (max-width: 576px) {
  .section_top2 {
    margin-top: 0rem;
  }
  .section_top8 {
    /* margin */
    padding-top: 40px;
    margin-bottom: 0rem;
  }
}
