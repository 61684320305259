.why_to_choose_card {
  border-radius: 30px;
  margin-bottom: 2rem;
  width: 320px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(205, 126, 217, 0.05);
  border: none;
  padding: 24px;
}

.why_to_choose_card_img_top {
  width: 45%;
  height: auto;
  padding-top: 0.5rem;
}

.card_title {
  color: #272727;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.6px;
}

.content {
  color: #5a5a5a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
}

.bold {
  color: #272727;
}

.card_body {
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 1500px) {
  .card_title {
    font-size: 1.5rem;
  }
  .content {
    font-size: 1.3rem;
  }
}

@media (max-width: 550px) {
  .card_title {
    font-size: 18px;
  }

  .content {
    font-size: 14px;
  }
}
