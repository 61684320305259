.cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 912px;
    height: 620px;
    background: #FFFFFF;
    border-radius: 24px;
}

.topLogo {
    width: 214px;
    height: 214px;
    background: #FFFFFF;
    flex: none;
    flex-grow: 0;
}

.topHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 59%;
    height: 106px;
    margin-bottom: 30px;
}


.topHeading__first {
    width: 100%;
    height: 51px;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    color: #00183D;
}

.topHeading__second {
    width: 100%;
    height: auto;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #666666;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.info {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 6px;
    width: 69%;
    height: auto;
    background: #EBF4FF;
    border-radius: 8px;
}

.info>text {
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #1A73E8;
}

.bottomButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;
    gap: 8px;
    width: 69%;
    height: 56px;
    background: #FED462;
    border-radius: 8px;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;

}

@media (max-width: 1000px) {
    .cont {
        width: 800px;
    }
}

@media (max-width: 850px) {
    .cont {
        width: 700px;
    }

    .topHeading__first {
        font-size: 32px;
    }
}

@media (max-width: 750px) {
    .cont {
        width: 600px;
    }

    .info>text {
        font-size: 15px;
    }
}

@media (max-width: 650px) {
    .cont {
        width: 510px;
    }

    .topHeading__first {
        font-size: 28px;
    }

    .topHeading__second {
        font-size: 16px;
    }

    .info {
        width: 75%;
    }
}

@media (max-width: 550px) {
    .cont {
        width: 430px;
    }

    .topHeading__first {
        font-size: 24px;
    }
}

@media (max-width: 450px) {
    .cont {
        width: 440px;
    }

    .topHeading__first {
        font-size: 23px;
    }

    .info {
        width: 80%;
    }

}

@media (max-width: 442px) {
    .cont {
        width: 430px;
    }

    .bottomButton {
        width: 73%;
    }
}

@media (max-width: 432px) {
    .cont {
        width: 422px;
    }

    .bottomButton {
        width: 75%;
    }
}

@media (max-width: 423px) {
    .cont {
        width: 400px;
    }

    .topHeading__first {
        font-size: 21px;
    }

    .bottomButton {
        width: 77%;
    }
}

@media (max-width: 405px) {
    .cont {
        width: 385px;
    }

    .topHeading__first {
        font-size: 20px;
        width: 140%;
    }

}

@media (max-width: 395px) {
    .cont {
        width: 380px;
    }

    .bottomButton {
        width: 83%;
    }
}

@media (max-width: 385px) {
    .cont {
        width: 365px;
    }

}

@media (max-width: 370px) {
    .cont {
        width: 350px;
    }

}

@media (max-width: 354px) {
    .cont {
        width: 320px;
    }

}