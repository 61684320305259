.optionButton {
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 100%;
  padding: 8px 14px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  color: rgba(73, 72, 72, 1);
}

.optionButton:hover {
  background-color: rgba(18, 37, 119, 0.05);
}

.optionButton:focus {
  outline: 2px solid rgba(18, 37, 119, 0.5);
  outline-offset: 2px;
}

.optionButton.selected {
  background-color: rgba(18, 37, 119, 0.1);
  border-color: rgba(18, 37, 119, 1);
  color: rgba(18, 37, 119, 1);
}

.selectionGroup {
  margin-top: 24px;
}

.sectionTitle {
  color: rgba(18, 37, 119, 1);
  font-size: 16px;
  font-weight: 420;
  line-height: 1;
  margin-bottom: 12px;
}

.optionsGrid {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.optionRow {
  display: flex;
  width: 100%;
  gap: 8px;
}
