.serialNumber {
  display: flex;
  width: 26px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  color: #000;
  background-color: #fed462;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
  margin-right: 0.7rem;
  padding-left: 2px;
}

.accordion {
  margin-top: 100px;
  position: relative;
  top: -25%;
  width: 100%;
  padding: 30px;
  border-radius: 30px;
  background-color: #f8f9ff;
}

.accord_i {
  font-size: 0.9rem;
  padding: 0.3rem 0.58rem;
  margin-right: 10px;
  background-color: #fed462;
  border: none;
  border-radius: 50%;
}

.accordion .accordian-title {
  margin-left: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.accordion .accordion_content .question {
  padding: 15px 25px 15px 0;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.089);
  font-size: 18px;
  font-weight: 600;
  display: flex;
  position: relative;
}

.accordion_content .question::after {
  top: 6%;
  content: "+";
  font-size: 2rem;
  position: absolute;
  right: 10px;
  color: #000;
}

.accordion .accordion_content .answer {
  padding-top: 15px;
  padding-left: 25px;
  font-size: 17px;
  line-height: 1.2;
  width: 100%;
  overflow: hidden;
  height: 0px;
  opacity: 0;
  transition: all 0.7s ease;
}

.accordion .accordion_content.active .answer {
  height: fit-content;
  opacity: 1;
}

.accordion .accordion_content.active .question {
  font-size: 1rem;
  transition: 0.5s;
}

.questionContent {
  width: 90%;
  word-break: keep-all;
}

.accordion .accordion_content.active .question::after {
  position: absolute;
  top: -20%;
  content: "-";
  font-size: 3rem;
  transition: 0.5s;
}

@media (min-width: 1500px) {
  .accordion .accordion_content .question {
    font-size: 1.4rem;
  }

  .accordion .accordion_content.active .question {
    font-size: 1.5rem;
  }

  .accordion .accordion_content .answer {
    font-size: 1.3rem;
  }

  .accordion .accordion_content.active .answer {
    font-size: 1.3rem;
  }
}

@media (max-width: 768px) {
  .serialNumber {
    padding-left: 0px;
  }

  h2 {
    font-size: 26px;
  }
  .accordion {
    width: auto;
    padding: 20px;
  }

  .accordion_content .question::after {
    top: 8%;
    font-size: 1.5rem;
    right: 0px;
  }

  .accordion .accordion_content.active .question::after {
    top: -15%;
    font-size: 2.5rem;
  }

  .accordion .accordion_content .question {
    font-size: 16px;
  }

  .accordion .accordion_content .answer {
    font-size: 16px;
  }

  .accordion .accordion_content.active .answer {
    height: fit-content;
  }

  .accordion .accordion_content.active .question {
    font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .accordion {
    margin-top: 100px;
    position: relative;
    top: -20%;
    width: 100%;
    padding: 30px;
  }
  .accordion .accordion_content.active .answer {
    height: auto;
    font-size: 14px;
    line-height: 16.71px;
  }
}

/* @starting-style {
  .answer {
    opacity: 0;
  }
} */
