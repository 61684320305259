.navbar {
  z-index: 999;
  width: 100%;
  height: 100px;
  top: 0;
  padding: 1rem 2rem;
  overflow: hidden;
  background-color: #fff;
  backdrop-filter: blur(9.699999809265137px);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  border: none;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.6);
}

.logo {
  position: relative;
  top: -4px;
  cursor: pointer;
}

.navbar .links {
  cursor: pointer;
  display: flex;
  gap: 2rem;
}

.navbar .toggle_btn {
  margin-top: -0.9rem;
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}

li {
  list-style: none;
}

.active_section {
  font-size: 16px;
  color: #1489e3;
  font-weight: bolder;
}

.header_option {
  font-size: 16px;
  color: #000;
}

ul {
  margin-top: 0;
  margin-bottom: 0rem;
}

.account {
  padding: 0.5rem 1.5rem;
  /* border: none; */
  outline: none;
  border-radius: 10px;
  /* color: #000;
    background-color: #fff; */
}

.account:hover {
  background-color: #ff8a00;
  color: #fff;
}

.dropdown_menu {
  display: none;
  position: fixed;
  right: 2rem;
  top: 60px;
  height: 0;
  width: 300px;
  background: aliceblue;
  backdrop-filter: blur(15px);
  border-radius: 10px;
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.dropdown_menu.open {
  margin-top: 5px;
  height: auto;
  /* width: 100%; */
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(25px);
}

.dropdown_menu li {
  padding: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown_menu .account {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 1024px) {
  .navbar .links,
  .navbar .account {
    display: none;
  }

  .navbar .toggle_btn {
    display: block;
    position: relative;
    top: 9px;
  }

  .dropdown_menu {
    z-index: 999;
    display: block;
  }
}

@media (max-width: 576px) {
  .navbar {
    height: 80px;
  }
  .dropdown_menu {
    left: 2rem;
    width: unset;
  }
}
