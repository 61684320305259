.container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 60px;
}

.header {
    background-image: url('../../../public/Assets/Modules/Home/FAQ/faqback.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    background-color: #061244;
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 100px;
    text-align: center;
}

.childContent {
    padding: 20px 400px;
}

.headerContent {
    display: flex;
    width: 692px;
    max-width: 100%;
    flex-direction: column;
    justify-content: start;
}

.headerWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
}

.label {
    color: #bfbfbf;
    font-size: 24px;
    line-height: 52px;
    letter-spacing: 0.48px;
    align-self: center;
}

.title {
    color: #fff;
    font-size: 36px;
    font-weight: 420;
    line-height: 1;
    margin-top: 6px;
}

.subtitle {
    color: #d7d7d7;
    font-size: 20px;
    line-height: 38px;
    margin-top: 6px;
}

.content {
    align-self: center;
    display: flex;
    margin-top: 62px;
    width: 100%;
    max-width: 1366px;
    gap: 40px 52px;
    flex-wrap: wrap;
    padding: 20px;
}

.policyText {
    color: #000;
    align-self: start;
    flex-grow: 1;
    width: 1293px;
    flex-basis: auto;
    font: 400 18px/28px SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif;
}

.scrollWrapper {
    display: flex;
    gap: 10px;
    justify-content: start;
    padding: 4px;
}

.scrollContent {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    flex-direction: column;
    flex: 1;
    flex-basis: 0%;
}

.scrollBar {
    border-radius: 8px;
    background-color: #122577;
    display: flex;
    height: 173px;
}

.textBold {
    font-family: Sequel Sans, sans-serif;
    font-weight: 600;
    font-size: 20px;
}

.textRegular {
    font-family: Sequel Sans, sans-serif;
    font-weight: 310;
}

.textLight {
    font-family: Sequel Sans, sans-serif;
    font-weight: 310;
    font-size: 16px;
}

.list {
    list-style-type: disc;
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.listItem {
    margin-bottom: 8px;
}

@media (max-width: 991px) {
    .container {
        margin-top: 80px;
    }

    .childContent {
        padding: 20px;
    }

    .header {
        max-width: 100%;
        padding: 0 20px;
    }

    .headerWrapper {
        max-width: 100%;
    }

    .label {
        max-width: 100%;
    }

    .title {
        max-width: 100%;
    }

    .subtitle {
        max-width: 100%;
    }

    .content {
        max-width: 100%;
        margin-top: 40px;
    }

    .policyText {
        max-width: 100%;
    }

    .scrollWrapper {
        display: none;
    }

    .scrollContent {
        padding-bottom: 100px;
    }
}