/* general */

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 3px rgb(108, 131, 138);
}

/* Removing up down default arrow in input tag with type = number

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove arrows for all browsers (additional compatibility) */
input[type="number"] {
  appearance: textfield;
}

/* ------------------------------------------------------------- */

.cont {
  width: 100%;
  height: 95%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 87%;
  height: 105px;
}

.top__img {
  width: 152px;
  height: 32px;
  gap: 0px;
  /* opacity: 0px; */
}

.top__head {
  width: 100%;
  height: 43px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 42.96px;
  text-align: left;
  color: #00183d;
}

.middle {
  display: flex;
  flex-direction: column;
  width: 87%;
  gap: 30px;
}

.middle__continueWithGoogle {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  /* opacity: 0px; */
  background: #fffaeb;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.middle__continueWithGoogle__icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.middle__continueWithGoogle > h3 {
  width: 178px;
  height: 21px;
  gap: 0px;
  /* opacity: 0px; */
  margin-left: 10px;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.48px;
  text-align: left;
}

.middle__divider {
  position: relative;
  width: 100%;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle__divider__line {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #cccccc;
}

.middle__divider__content {
  position: absolute;
  width: auto;
  height: auto;
  padding: 0 5px;
  /* opacity: 1; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  text-align: center;
  background: white;
  color: #949494;
}

.middle__entries {
  width: 100%;
  gap: 30px;
  /* opacity: 0px; */
  display: flex;
  flex-direction: column;
}

.middle__entries__field {
  width: 100%;
  gap: 12px;
  /* opacity: 0px; */
  display: flex;
  flex-direction: column;
  position: relative;
}

.middle__entries__field__inputVerify {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  position: relative;
}

.middle__entries__field__expand {
  width: 100%;
  height: 46px;
  border-style: none;
  background: #f2f2f2;
  gap: 8px;
  border-radius: 8px;
  /* opacity: 0px; */
  transition: width 0.2s ease;
  padding-left: 10px;
}

.middle__entries__field__shrink {
  width: 85%;
  height: 46px;
  border-style: none;
  background: #f2f2f2;
  gap: 8px;
  border-radius: 8px;
  /* opacity: 0px; */
  transition: width 0.2s ease;
  padding-left: 10px;
}

.middle__entries__field__verifyButton__hide {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-25%);
  width: 10%;
  height: 46px;
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #1a73e8;
  visibility: hidden;
}

.middle__entries__field__verifyButton__show {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  margin-left: 10px;
  height: 46px;
  font-family: "SF Pro Display", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #1a73e8;
  visibility: visible;
  transition: visibility 1s ease;
  cursor: pointer;
}

@keyframes infiniteRotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.middle__entries__field__verificationStage0Icon {
  font-size: 2rem;
  color: #af4c4c;
}

.middle__entries__field__verificationStage1Icon {
  font-size: 2rem;
  color: #4caf50;
  animation: infiniteRotation 1s linear infinite;
}

.middle__entries__field__verificationStage2Icon {
  font-size: 2rem;
  color: #4caf50;
}

.middle__entries__field__head {
  width: 100%;
  height: 19px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: left;
}

.middle__entries__field__cover {
  width: 100%;
  height: 46px;
  gap: 8px;
  position: relative;
}

.middle__entries__field__otpHead {
  width: 100%;
  height: 19px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.middle__entries__field__otpCover {
  width: 100%;
  height: 46px;
  gap: 8px;
}

.middle__entries__field__eyeIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.middle__entries__agree {
  width: 100%;
  height: 27px;
  gap: 0px;
  display: flex;
  align-items: center;
  /* opacity: 0px; */
  position: relative;
  margin-top: 20px;
  margin-bottom: 35px;
}

.middle__entries__agree > input {
  width: 24px;
  height: 24px;
  padding: 2px;
  gap: 0px;
  border-radius: 6px;
  /* opacity: 0px; */
  cursor: pointer;
}

.middle__entries__agree > label {
  margin-left: 10px;
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  text-align: left;
}

.middle__entries__agree > label > span {
  border-bottom: 1px solid;
  cursor: pointer;
}

.middle__entries__rememberMe__right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 27px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: right;
  color: #1a73e8;
  cursor: pointer;
}

.bottom {
  width: 87%;
  height: 113px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.bottomButton {
  border-style: none;
  width: 100%;
  height: 56px;
  padding: 16px 30px 16px 30px;
  gap: 8px;
  border-radius: 8px;
  /* opacity: 0px; */
  background: #fed462;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.bottomButton.disabledBtn {
  background: rgba(176, 175, 175, 0.707);
}

.bottom__login {
  display: flex;
  width: 100%;
  height: 27px;
  gap: 8px;
  /* opacity: 0px; */
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.bottom__login__left {
  width: 63%;
  height: 27px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-align: right;
}

.bottom__login__right {
  width: 35%;
  height: 27px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #1a73e8;
  cursor: pointer;
}

.error {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  color: red;
  font-size: 0.9rem;
  height: auto;
  border-radius: 5px;
  width: auto;
  padding: 5px;
}

@media (max-width: 1200px) {
  .top {
    margin-top: 30px;
    margin-bottom: 60px;
  }

  .top__head {
    font-size: 33px;
  }

  .middle {
    margin-bottom: 40px;
  }

  .bottom {
    margin-bottom: 30px;
  }
}

@media (max-width: 700px) {
  .middle,
  .bottom {
    width: 90%;
  }

  .cont {
    height: 98%;
  }

  .middle__entries__field__shrink {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .middle__entries__agree {
    margin-top: 70px;
  }

  .middle,
  .bottom {
    width: 96%;
  }
}
