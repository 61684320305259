.container {
  display: flex;
  flex-direction: column;
  font-family: Sequel Sans, sans-serif;
  align-items: center;
  margin: 40px;
}

.contentWrapper {
  display: flex;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  justify-content: start;
  position: relative;
}

.headerWrapper {
  display: flex;
  width: 784px;
  max-width: 100%;
  flex-direction: column;
  justify-content: start;
}

.videoLabel {
  color: rgba(18, 37, 119, 1);
  font-size: 18px;
  font-weight: 410;
  line-height: 1;
  letter-spacing: 1.71px;
}

.title {
  background-blend-mode: normal;
  color: rgba(20, 24, 62, 1);
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.36px;
  margin-top: 8px;
}

.videoContainer {
  position: relative;
  width: 100%;
  margin-top: 28px;
  border-radius: 24px;
  overflow: hidden;
  aspect-ratio: 16/9;
}

.videoPreview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.playButton:hover {
  background-color: rgba(255, 255, 255, 1);
}

.playButton:focus {
  outline: 2px solid rgba(18, 37, 119, 1);
  outline-offset: 2px;
}

.playIcon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 25px;
  border-color: transparent transparent transparent rgba(18, 37, 119, 1);
  margin-left: 5px;
}

.description {
  color: rgba(10, 10, 10, 1);
  font-size: 28px;
  font-weight: 410;
  line-height: 47px;
  letter-spacing: 1.28px;
  /* text-align: center; */
  margin-top: 34px;
  max-width: 913px;
}

@media (max-width: 991px) {
  .contentWrapper,
  .headerWrapper,
  .videoLabel,
  .title,
  .videoContainer,
  .description {
    max-width: 100%;
  }
  
  .description {
    font-size: 24px;
    line-height: 36px;
    padding: 0 20px;
  }
}