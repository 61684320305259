.cont {
  /* width: 100vw; */
  height: auto;
  display: flex;
  padding: 100px;
  justify-content: center;
  align-items: center;
}

.cont__left {
  width: 40%;
  height: 100vh;
  display: flex;
  justify-content: end;
  align-items: center;
  /* border: 1px solid; */
}

.cont__left__inside {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  /* border: 1px solid; */
}

.cont__left__inside span {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  border-radius: 4px;
}

.cont__left__inside h1 {
  color: #00183d;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cont__left__inside p {
  color: #5a5a5a;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 3rem;
}

.cont__right {
  margin-left: 60px;
  width: auto;
  height: 100vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* border: 1px solid; */
}

.cont__right img {
  width: auto;
  height: 90%;
}

.subHeading{
  color: #5A5A5A;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  }

@media (max-width: 1800px) {
  .cont__left {
    width: 45%;
  }
}

@media (max-width: 1600px) {
  .cont__left {
    width: 50%;
  }

  .cont__left__inside {
    margin-bottom: 2rem;
  }

  .cont__right img {
    width: auto;
    height: 85%;
  }
}

@media (max-width: 1520px) {
  .cont__left__inside h1 {
    font-size: 55px;
  }

  .cont__left__inside p {
    font-size: 20px;
  }

  .cont__right {
    margin-left: 40px;
  }

  .cont__right img {
    width: auto;
    height: 81%;
  }
}

@media (max-width: 1450px) {
  .cont {
    padding-bottom: 50px;
  }

  .cont__right {
    margin-left: 30px;
  }

  .cont__right img {
    width: auto;
    height: 78%;
  }
}

@media (max-width: 1350px) {
  .cont {
    padding-bottom: 35px;
  }

  .cont__right {
    margin-left: 15px;
  }

  .cont__right img {
    width: auto;
    height: 73%;
  }
}

@media (max-width: 1400px) and (max-height: 860px) {
  .cont__left__inside {
    margin-bottom: 4rem;
  }

  .cont__right img {
    height: 82%;
    width: auto;
  }

  .cont__left__inside h1 {
    font-size: 50px;
  }

  .cont__left__inside p {
    font-size: 19px;
  }
}

@media (max-width: 1280px) {
  .cont {
    padding: 50px;
    padding-top: 100px;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .cont__left {
    margin-top: 5rem;
    width: 90%;
    height: auto;
  }

  .cont__right {
    margin-top: 3rem;
    width: 90%;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .cont__right img {
    height: 50%;
    width: auto;
    padding: 0rem;
  }
}

@media (max-width: 900px) {
  .cont__left {
    width: 100%;
  }

  .cont__right {
    width: 100%;
  }

  .cont__right img {
    width: 100%;
    height: auto;
  }
}

/* @media (max-width: 780px) {
      .cont {
          padding-left: 40px;
          padding-right: 40px;
      }
  
  } */

/* @media (max-width: 650px) {
      .cont {
          padding: 50px
          padding
      }
  } */

@media (max-width: 550px) {
  .cont {
    padding: 30px;
    padding-top: 60px;
  }

  .cont__left {
    width: 100%;
    margin-top: 2rem;
  }

  .cont__left__inside {
    margin-bottom: 1rem;
  }

  .cont__left__inside h1 {
    font-size: 30px;
    margin-top: 0.5rem;
  }

  .cont__left__inside p{
    font-size: 15px;
    margin-bottom: 1rem;
  }

  .cont__left__inside span {
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 10px;
    /* chnaged font */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    border-radius: 4px;
  }
  .subHeading{
    font-size: 20px;
    font-weight: 400;
    }
}
