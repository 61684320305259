.blogSection {
    padding: 20px;
    background: #fff;
    margin: 20px;
}

.header {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
}

.header h3 {
    font-size: 24px;
    color: #122577;
}

.header h2 {
    font-weight: 600;
}

.header p {
    font-size: 16px;
    color: #a7a7a7;
}

.seeAll {
    background-color: #122577;
    color: white;
    padding: 12px 20px;
    border-radius: 6.25rem;
    cursor: pointer;
    font-size: 24px;
}

.blogContainer {
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    padding-top: 10px;
    padding-bottom: 10px;
    scrollbar-width: none;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    cursor: grab;
}

.blogContainer:active {
    cursor: grabbing;
}

.blogContainer::-webkit-scrollbar {
    display: none;
}

.blogCard {
    flex: 0 0 calc(25% - 20px);
    min-width: calc(25% - 20px);
    max-width: calc(25% - 20px);
    height: 650px;
    border-radius: 10px;
    overflow: hidden;
    background: white;
    user-select: none;
    transition: transform 0.3s ease;
    position: relative;
}

.blogCard:hover,
.blogCard:active {
    transform: translateY(-10px);
}

.blogImage {
    width: 100%;
    height: 80%;
    object-fit: cover;
    object-position: top;
}

.categoryTag {
    position: absolute;
    background: #fff;
    padding: 5px 10px;
    top: 20px;
    left: 10px;
    border-radius: 20px;
}

.blogInfo {
    padding: 15px;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blogHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.profilePic {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.name {
    font-size: 14px;
    font-weight: bold;
}

.separator {
    margin: 0 10px;
    color: #757575;
}

.date {
    font-size: 14px;
    color: #757575;
    margin-left: auto;
}

.blogTitle {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .blogCard {
        flex: 0 0 calc(100% - 20px);
        min-width: calc(100% - 20px);
        max-width: calc(100% - 20px);
    }
}

@media (max-width: 480px) {
    .blogContainer {
        padding: 0 10px;
    }

    .blogCard {
        flex: 0 0 calc(100% - 40px);
        min-width: calc(100% - 40px);
        max-width: calc(100% - 40px);
        margin-right: 20px;
    }
}