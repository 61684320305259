.top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.top__inside {
  margin-top: 130px;
  display: flex;
  width: 76%;
  justify-content: space-between;
  align-items: center;
  height: 333px;
}

.top__inside h3 {
  color: #272727;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.top__inside a {
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  margin-top: 10px;
}

.top__inside text {
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  margin-top: 10px;
}

.top__inside text {
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  margin-top: 10px;
}

.top__inside__first {
  /* width: 40%; */
  height: 100%;
  /* width: 29%;
  height: 100%; */
}

.top__inside__first p {
  margin-top: 30px;
  width: 85%;
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
}

.top__inside__first span {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
  width: 80%;
}

.top__inside__first i {
  border-radius: 50%;
  padding: 7px;
  font-size: 18px;
  cursor: pointer;
}

.top__inside__second {
  width: 14%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.top__inside__third {
  width: 14%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.top__inside__fourth {
  width: 14%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.top__inside__fourth text:hover {
  color: orange;
}

.top__inside__fifth {
  width: 26%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.top__inside__fifth__item {
  width: 100%;
  padding: 10px;
  display: flex;
}

.top__inside__fifth__item i {
  /* width: 20%; */
  padding: 5px 0;
  /* margin-left: 10px; */
  font-size: 18px;
}

.top__inside__fifth__item > span {
  width: 70%;
  margin-left: 20px;
}

.divider {
  width: 100%;
  height: 2px;
  background: rgba(0, 0, 0, 0.1);
}

.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 30px; */
}

.bottom__inside {
  width: 76%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center; */
}

.bottom__left {
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: auto;
}

.bottom__right {
  width: auto;
  display: flex;
  justify-content: space-evenly;
}

.bottom__right__first {
  margin: 10px;
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
}

.bottom__right__second {
  margin: 10px;
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
}

.bottom__right__third {
  margin: 10px;
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
}

@media (max-width: 1800px) {
  .top__inside,
  .bottom__inside {
    width: 80%;
  }
}

@media (max-width: 1650px) {
  .top__inside,
  .bottom__inside {
    width: 85%;
  }
}

@media (max-width: 1450px) {
  .top__inside,
  .bottom__inside {
    width: 89%;
  }
}

@media (max-width: 1200px) {
  .top__inside,
  .bottom__inside {
    width: 92%;
  }
}

@media (max-width: 1030px) {
  .top__inside {
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
    margin-top: 7rem;
  }

  .top__inside__first {
    width: 100%;
  }

  .top__inside__second {
    width: 80%;
    margin-top: 20px;
  }

  .top__inside__third {
    width: 80%;
    margin-top: 20px;
  }

  .top__inside__fourth {
    width: 80%;
    margin-top: 20px;
  }

  .top__inside__fifth {
    width: 100%;
    margin-top: 20px;
  }

  .bottom__inside {
    flex-direction: column;
    align-items: baseline;
    padding-bottom: 20px;
  }

  .bottom__left {
    padding-left: 10px;
  }
}

@media (max-width: 768px) {
  .top__inside__first p {
    width: 200%;
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .top__inside__first p {
    width: 200%;
    font-size: 14px;
  }

  .top__inside h3 {
    font-size: 18px;
  }

  .top__inside__first span {
    margin: 20px 0px 10px 0px;
    gap: 20px;
  }

  .top__inside__first i {
    padding: 6px;
    font-size: 16px;
  }

  .top__inside text,
  .top__inside a,
  .bottom__left,
  .bottom__right__first,
  .bottom__right__second,
  .bottom__right__third {
    font-size: 14px;
  }
}
