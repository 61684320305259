.cont {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 140px;
}

.cont_inside {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 100%;
}

.cont h2 {
  font-size: 2rem;
  font-weight: 700;
}

.cont span {
  font-weight: 700;
}

.data_section {
  height: 100%;
  width: 25%;
  padding-left: 5%;
  padding-right: 2%;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
}

.data_section_top {
  height: 50%;
  display: flex;
  align-items: end;
}

.data_section_bottom {
  display: flex;
  height: 50%;
}

@media (max-width: 1200px) {
  .data_section p {
    font-size: 1rem;
  }

  .cont h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 700px) {
  .data_section_top {
    height: 40%;
  }

  .data_section p {
    font-size: 0.9rem;
  }

  .data_section_bottom {
    height: 60%;
  }
}

@media (max-width: 550px) {
  .data_section_top {
    height: 40%;
  }

  .cont h2 {
    font-size: 20px;
  }

  .data_section p {
    font-size: 12px;
  }

  .data_section_bottom {
    height: 60%;
  }
}
