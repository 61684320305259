.section_top1,
.section_top3,
.section_top7 {
  background-color: #f8f9ff;
}
.container {
  padding: 2rem 8vw;
}

.containerSection {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.heading {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 3rem;
  font-weight: 600;
  font-size: 36px;
  text-align: center;
}

.section_subHeading {
  color: #5a5a5a;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 4rem;
  margin-top: 1rem;
}

.section_subHeading2 {
  color: #21113d;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  margin-top: 1rem;
}

.section_top8_img {
  margin-top: -3rem;
}

@media (min-width: 1500px) {
  .heading {
    font-size: 2.4vw;
  }

  .section_subHeading {
    font-size: 1.4vw;
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 26px;
  }

  .section_subHeading {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .section_top8_img {
    width: 100%;
    height: 100%;
  }

  .section_subHeading {
    font-size: 14px;
  }
}
