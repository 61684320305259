.cont {
    width: 100%;
    height: 95%;
    margin-top: 20px;
}

.top__heading {
    color: #012C6B;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.signup__first {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 36px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border: 2px solid #F2F2F2;
    border-radius: 8px;
}

.signup__entries {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 100%;
    height: auto;
}

.signup__entries text {
    width: 100%;
    height: 19px;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
}

.signup__entries input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: 100%;
    height: 46px;
    background: #F2F2F2;
    border-radius: 8px;
    border-style: none;
}

.signup__entries__title {
    color: #00183D;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.signup__entries__description {
    height: auto;
    width: 100%;
    color: #666;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.6px;
}

.signup__entries select {
    border-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 8px;
    width: 100%;
    height: auto;
    background: #F2F2F2;
    border-radius: 8px;
    color: grey;

    /* For Safari */
    -webkit-appearance: none;
    /* For Firefox */
    -moz-appearance: none;
    appearance: none;
}

.signup__entries__select__wrapper {
    width: 100%;
    height: auto;
    position: relative;
}

.signup__entries__dropIcon {
    font-size: 18px;
    color: #165DC9;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    border-style: none;
}

.signup__second {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 36px;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border: 2px solid #F2F2F2;
    border-radius: 8px;
    margin-top: 20px;
}

.nextBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;
    font-size: 18px;
    gap: 6px;
    width: 100%;
    height: 56px;
    background: #FED462;
    border-radius: 8px;
    border-style: none;
}

.seperation {
    padding: 10px;
}

.error {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    color: red;
    font-size: 0.9rem;
    height: auto;
    border-radius: 5px;
    width: auto;
    height: auto;
    padding: 5px;
}

@media (max-width: 600px) {

    .signup__entries {
        width: 100%;
    }

    .signup__first,
    .signup__second {
        padding: 30px 5px;
    }

}