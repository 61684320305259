/* .ourProcess_page{
    padding: 0rem 7% 5% 7%;    
} */

.even {
  display: flex;
  flex-direction: row-reverse;
}

.description {
  color: #5a5a5a;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 4rem;
  margin-top: 2rem;
}

.section_ourProcess_cont {
  height: auto;
  width: 100%;
}

.ourProcess_section {
  width: 100%;
  min-height: 143px;
  display: flex;
}

.heading {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 33.6px;
}

.content p {
  color: #5a5a5a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px; /* 140% */
}

.ourProcess_section_left {
  width: 43%;
  display: flex;
  flex-direction: row-reverse;
  padding-top: 20px;
  padding-bottom: 20px;
}

.even_number {
  display: flex;
  flex-direction: row;
}

.ourProcess_section_left_inside {
  text-align: right;
  font-size: 120px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  opacity: 0.03;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.ourProcess_section_middle {
  width: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ourProcess_section_middle_top {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
}

.ourProcess_section_middle_top_inside {
  width: 40%;
  height: 100%;
  border-radius: 50%;
}

.ourProcess_section_middle_bottom {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourProcess_section_right {
  width: 43%;
  display: flex;
}

.ourProcess_section_right_content {
  display: flex;
  padding: 20px;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  position: relative;
  height: fit-content;
}

.ourProcess_section_triangle {
  width: 30px;
  height: 30px;
  position: absolute;
  left: -27px;
}

.ourProcess_section_triangle_even {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -27px;
}

@media (min-width: 1500px) {
  .heading {
    font-size: 1.5rem;
  }
  .content {
    font-size: 1.3rem;
  }
}

@media (max-width: 995px) {
  .ourProcess_section .ourProcess_section_left {
    display: none;
  }

  .ourProcess_section .ourProcess_section_right {
    width: 100%;
    margin: 0rem 0rem 1.5rem 1.5rem;
  }

  .even {
    display: flex;
    flex-direction: row;
  }
  .ourProcess_section_triangle,
  .ourProcess_section_triangle_even {
    display: none;
  }

  .ourProcess_section_middle_top_inside {
    width: 70%;
    height: 100%;
    border-radius: 50%;
  }
}

@media (max-width: 550px) {
  .content {
    font-size: 14px;
  }
  .heading {
    font-size: 18px;
  }
}

@media (max-width: 460px) {
  .ourProcess_section .ourProcess_section_right {
    width: 100%;
    margin: 0rem 0rem 1rem 0.5rem;
  }

  .ourProcess_section_middle_top_inside {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
}
