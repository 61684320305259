.formContainer {
    border-radius: 24px;
    background: #fff;
    display: flex;
    max-width: 716px;
    flex-direction: column;
    padding: 24px 40px 46px;
    position: relative;
    max-height: 100vh;
}

.closeButton {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 24px;
}

.closeIcon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 30px;
}

.formContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.header {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: Sequel Sans, sans-serif;
    text-align: center;
    align-items: center;
}

.headerIcon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 68px;
}

.headerText {
    margin-top: 24px;
    width: 100%;
}

.title {
    color: #0a0a0a;
    font-size: 24px;
    font-weight: 420;
    line-height: 1;
    margin: 0;
}

.description {
    color: #666;
    font-size: 18px;
    font-weight: 405;
    line-height: 32px;
    margin: 16px 0 0;
}

.form {
    display: flex;
    margin-top: 42px;
    width: 100%;
    flex-direction: column;
    gap: 16px;
}

.inputField {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.label {
    color: #041b2d;
    font-size: 14px;
    font-weight: 405;
    line-height: 1;
    letter-spacing: 0.28px;
    display: block;
    margin-bottom: 6px;
}

.required {
    color: #dc2626;
    margin-left: 2px;
}

.input {
    border-radius: 8px;
    border: 1px solid #eee;
    background: #fff;
    padding: 10px 14px;
    width: 100%;
    font-size: 16px;
    color: #041b2d;
    letter-spacing: 0.32px;
}

.errorInput {
    border: 1px solid red;
}

.errorText {
    color: red;
    font-size: 12px;
    margin-top: 4px;
    margin-bottom: 8px;
}

.input::placeholder {
    color: #8f9294;
}

.phoneInputWrapper {
    display: flex;
    border-radius: 8px;
    border: 1px solid #eee;
    background: #fff;
    overflow: hidden;
}

.countryCode {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px 12px;
    border-right: 1px solid #eee;
}

.countryFlag {
    width: 18px;
    height: 18px;
    object-fit: contain;
}

.countryDialCode {
    color: #041b2d;
    font-weight: 410;
}

.dropdownArrow {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.phoneInput {
    flex: 1;
    border: none;
    padding: 10px 14px;
    font-size: 16px;
    color: #041b2d;
    letter-spacing: 0.32px;
}

.phoneInput::placeholder {
    color: #8f9294;
}

.selectWrapper {
    position: relative;
    border-radius: 8px;
}

.select {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #eee;
    background: #fff;
    padding: 10px 14px;
    font-size: 16px;
    color: #041b2d;
    appearance: none;
}

.select:invalid {
    color: #8f9294;
}

.selectArrow {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    pointer-events: none;
}

.textareaField {
    display: flex;
    flex-direction: column;
}

.textarea {
    border-radius: 8px;
    border: 1px solid #eee;
    background: #fff;
    padding: 10px 14px;
    width: 100%;
    min-height: 154px;
    font-size: 16px;
    color: #041b2d;
    letter-spacing: 0.32px;
    line-height: 24px;
    resize: vertical;
    font-family: inherit;
}

.textarea::placeholder {
    color: #8f9294;
}

.submitButton {
    align-self: center;
    border-radius: 100px;
    background-color: #122577;
    margin-top: 42px;
    min-height: 48px;
    width: 288px;
    color: #fff;
    letter-spacing: 0.48px;
    font: 415 16px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.submitButton:hover {
    background-color: #1a32a0;
}

.submitButton:focus {
    outline: none;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #122577;
}

@media (max-width: 991px) {
    .formContainer {
        /* padding: 20px; */
        /* max-width: 350px; */
    }

    .closeButton {
        right: 20px;
        top: 20px;
    }

    .submitButton {
        width: 100%;
        max-width: 288px;
    }
}

@media (max-width: 480px) {
    .description {
        font-size: 16px;
        line-height: 28px;
    }

    .form {
        margin-top: 32px;
    }

    .submitButton {
        margin-top: 32px;
    }
}