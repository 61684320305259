.section_top2,
.section_top4,
.section_top8 {
  background: linear-gradient(
    139deg,
    rgba(72, 207, 175, 0.2) 0%,
    rgba(65, 193, 201, 0.2) 100%
  );
}

.section_heading_color {
  color: #04033e;
}

.section_top8_img {
  margin-top: -5rem;
}
