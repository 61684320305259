.cont {
  width: 100%;
  height: 55%;
  display: flex;
  justify-content: center;
}

.cont__inside {
  position: relative;
  top: 60px;
  width: 77%;
  height: 450px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 0px 48.34px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  justify-content: end;
}

.cont__inside__left {
  height: 100%;
  width: 58%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont__inside__left__inside {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
}

.cont__inside__left h2 {
  color: #00183d;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cont__inside__left p {
  color: #666;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: 0.18px;
}

.cont__inside__left button {
  display: flex;
  height: 56px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 8px;
  gap: 20px;
  border-style: none;
}

.cont__inside__right {
  height: 100%;
  width: 38%;
  display: flex;
  justify-content: end;
  align-items: end;
}

.cont__inside__right img {
  height: 105%;
}

.smallImage {
  display: none;
}

.link{
  text-decoration: none;
}

@media (max-width: 1800px) {
  .cont__inside__left {
    width: 56%;
  }

  .cont__inside__right {
    width: 40%;
  }

  .cont__inside {
    width: 80%;
  }
}

@media (max-width: 1650px) {
  .cont__inside {
    width: 85%;
  }
}

@media (max-width: 1550px) {
  .cont__inside__left {
    width: 52%;
  }

  .cont__inside__left p {
    width: 87%;
  }

  .cont__inside__left h2 {
    font-size: 34px;
  }

  .cont__inside__right {
    width: 42%;
  }
}

@media (max-width: 1450px) {
  .cont__inside__left {
    width: 50%;
  }

  .cont__inside__right {
    width: 44%;
  }

  .cont__inside__left h2 {
    font-size: 33px;
  }

  .cont__inside {
    width: 89%;
  }
}

@media (max-width: 1200px) {
  .cont__inside__left {
    width: 50%;
  }

  .cont__inside {
    width: 94%;
  }

  .cont__inside__left h2 {
    font-size: 32px;
  }

  .cont__inside__left p {
    font-size: 18px;
  }

  .cont__inside__right {
    width: 44%;
  }

  .cont__inside__right img {
    width: 110%;
    height: auto;
  }
}

@media (max-width: 900px) {
  .cont__inside {
    flex-direction: column-reverse;
    height: auto;
    justify-content: center;
    align-items: center;
    /* padding-top: 10rem; */
    top: 130px;
    width: 80%;
    margin-bottom: 6rem;
  }
  .largeImage {
    display: none;
  }

  .smallImage {
    display: block;
    position: relative;
    top: -38.5%;
  }

  .cont__inside__left {
    width: 90%;
  }

  .cont__inside__left__inside {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin-top: -5rem;
    margin-bottom: 1rem;
  }

  .cont__inside__left h2 {
    font-size: 28px;
    width: 100%;
  }

  .cont__inside__left p {
    font-size: 18px;
    width: 100%;
  }

  .cont__inside__right {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .cont__inside__right img {
    width: 50%;
    height: auto;
    right: 0;
  }
}

@media (max-width: 600px) {
  .cont__inside__right img {
    width: 74%;
    height: auto;
  }
  .cont__inside__left h2 {
    font-size: 28px;
    width: 100%;
    margin-top: 1rem;
  }


  .cont__inside__left p {
    font-size: 15px;
    width: 100%;
  }
  .cont__inside__left button {
    font-size: 14px;
    height: 46px;
    padding: 0px 30px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 8px;
    gap: 20px;
    border-style: none;
  }
}
