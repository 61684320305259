/* .parent {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
} */

.child {
  position: relative;
  /* width: 80vw;
  height: 80vh; */
  border-radius: 24px;
  padding: 20px 50px;
  background: rgba(255, 255, 255, 1);
  /* overflow-y: scroll; */
}

.child p:first-of-type {
  margin-bottom: 1rem;
}

.child::-webkit-scrollbar {
  width: 10px;
}
.child::-webkit-scrollbar-thumb {
  height: 20px;
}
.child::-webkit-scrollbar-track {
  width: 10px;
}

.child h2 {
  text-align: center;
  margin-bottom: 40px;
  font-weight: bolder;
}

/* .child p {
} */

.heading {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  text-align: justified;
  margin-bottom: 20px;
}

.child ul {
  margin-bottom: 20px;
}

ul > li {
  list-style-type: circle;
}

.close {
  position: absolute;
  right: 3%;
  top: 3%;
  transform: scale(2);
  cursor: pointer;
}

.para_heading {
  text-align: left;
  font-size: 20px;
  text-decoration: underline;
  margin-bottom: 20px;
}

.section p {
  margin-bottom: 20px;
}

@media (max-width: 550px) {
  /* .parent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100000;
  } */

  .child {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 20px 10px;
    background: rgba(255, 255, 255, 1);
    /* overflow-y: scroll; */
  }

  .child li,
  p {
    font-size: 14px;
  }

  .child h2 {
    text-align: center;
    margin-bottom: 40px;

    /* text-decoration: underline; */
  }

  .close {
    position: absolute;
    right: 3%;
    top: 1%;
    transform: scale(1.7);
    cursor: pointer;
  }
}
