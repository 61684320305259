.otpContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.otpInput {
    width: 18%;
    height: 100px;
    font-size: 2rem;
    text-align: center;
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    font-weight: 600;
}