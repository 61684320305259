.introPage {
  position: absolute;
  /*require for scroll effect when directled from landing page to intro page */
}

.section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  transition: transform 1s;
}

.topElement {
  margin-top: 0rem;
  padding-top: 0rem;
}

.sticky {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
}

@media(max-width:1200px){
  .cont__inside{
    width: 90% !important;
  }
}
