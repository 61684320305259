.topSection {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 100vh;
  justify-content: center;
  position: relative;
}

.topSection::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: #0d0d0e;
}

.topSectionContent {
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(2vh + 1vw);
  z-index: 10;
}

.introTagline {
  color: #fff;
  text-align: center;
  font-size: clamp(2rem, 2rem + 1.5vw, 3.5rem);
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
}

.introText {
  color: #fff;
  text-align: center;
  text-shadow: 0 0 1.25rem rgba(83, 89, 255, 0.5);
  font-size: calc(1rem + 0.2vw);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.btnLayout {
  display: flex;
  flex-wrap: nowrap;
  gap: calc(0.5rem + 0.5vw);
}

.getStartedBtn {
  white-space: nowrap;
  width: calc(9.5rem + 8vw);
  height: calc(3.75rem + 2vh);
  display: flex;
  padding: calc(0.5% + 0.5vw);
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  border-radius: 6.25rem;
  background: #081656;
  color: #fff;
  font-size: calc(0.875rem + 0.3vw);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  outline: none;
  border: none;
}

.partLoginBtn {
  background-color: #ffffff;
  color: #081656;
}

.socialmediaLinks {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: calc(1.25rem + 1vh);
  width: calc(1.25rem + 1vw);
  position: absolute;
  left: 2%;
  bottom: 20%;
  height: 100%;
}

.topSocialmediaIcon {
  width: calc(0.875rem + 0.2vw);
  height: calc(0.875rem + 0.2vw);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.iconStyle {
  width: calc(1.5rem + 0.2vw);
  height: calc(1.5rem + 0.2vw);
}

.topSocialmediaIcon:hover {
  filter: drop-shadow(0.625rem 0.625rem 1.25rem rgba(83, 89, 255, 0.9));
  cursor: pointer;
  transform: scale(1.1) rotate(-90deg);
}

/* Mobile styles */
@media (max-width: 768px) {
  .topSection {
    height: 95vh;
  }

  .topSectionContent {
    width: 90%;
    justify-content: flex-start;
    gap: 5vh;
    margin-top: 12vh;
  }

  .introTagline {
    font-size: clamp(2rem, 6vw, 3.5rem);
  }

  .introText {
    font-size: clamp(1rem, 3.7vw, 1.125rem);
    line-height: 3.2vh;
  }

  .socialmediaLinks {
    flex-direction: row;
    justify-content: space-around;
    gap: 2.1875rem;
    width: 100%;
    height: auto;
    bottom: 5%;
    left: 0;
    right: 0;
  }

  .topSocialmediaIcon {
    width: 2.375rem;
    height: 2.375rem;
    transform: scale(1.4);
  }

  .btnLayout {
    justify-content: center;
  }

  .getStartedBtn {
    width: 100%;
    padding: 10%;
    font-size: 18px;
    height: auto;
    /* white-space: normal; */
  }
}

/* Tablet and small desktop styles */
@media (min-width: 769px) and (max-width: 1199px) {
  .topSectionContent {
    width: 85%;
  }

  .introTagline {
    font-size: clamp(2.2rem, 4vw, 3rem);
  }

  .introText {
    font-size: calc(0.9rem + 0.3vw);
  }

  .getStartedBtn {
    width: calc(10rem + 8vw);
    height: calc(3.5rem + 1.5vh);
    font-size: calc(0.8rem + 0.2vw);
  }

  .socialmediaLinks {
    left: 3%;
    bottom: 15%;
  }
}

/* Medium desktop styles */
@media (min-width: 1200px) and (max-width: 1499px) {
  .topSectionContent {
    width: 75%;
  }

  .introTagline {
    font-size: clamp(2.3rem, 2rem + 1.8vw, 3.8rem);
  }

  .introText {
    font-size: calc(1rem + 0.4vw);
  }

  .getStartedBtn {
    width: calc(11rem + 9vw);
    height: calc(3.6rem + 1.8vh);
    font-size: calc(0.85rem + 0.4vw);
  }

  .socialmediaLinks {
    gap: calc(1.5rem + 1.2vh);
  }
}

/* Large desktop styles */
@media (min-width: 1500px) {
  .introTagline {
    font-size: clamp(2.5rem, 2rem + 2vw, 24rem);
  }

  .introText {
    font-size: clamp(1rem, 0.875rem + 1vw, 12.5rem);
  }

  .topSocialmediaIcon {
    transform: scale(1.2);
  }

  .iconStyle {
    width: clamp(1.2rem, calc(0.8rem + 1vw), 10rem);
    height: clamp(1.2rem, calc(0.8rem + 1vw), 10rem);
  }

  .socialmediaLinks {
    gap: clamp(2.1875rem, calc(2.1875rem + 1vh + 1vw), 14rem);
    transform: scale(calc(1 + 0.01vw));
  }

  .getStartedBtn {
    font-size: calc(0.875rem + 0.7vw);
    padding: calc(3% + 0.5vw) calc(2.5% + 0.5vw);
  }
}