.container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 60px;
}

.header {
    background-image: url('../../../public/Assets/Modules/Home/FAQ/faqback.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    background-color: #061244;
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 100px;
    text-align: center;
    margin-bottom: 40px;
}

.childContent{
    padding: 20px 400px;
}

.headerContent {
    display: flex;
    width: 692px;
    max-width: 100%;
    flex-direction: column;
    justify-content: start;
}

.headerWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
}

.sectionLabel {
    color: #bfbfbf;
    font-size: 24px;
    line-height: 52px;
    letter-spacing: 0.48px;
    align-self: center;
}

.mainTitle {
    color: #fff;
    font-size: 36px;
    font-weight: 420;
    line-height: 1;
    margin-top: 6px;
}

.subtitle {
    color: #d7d7d7;
    font-size: 20px;
    line-height: 38px;
    margin-top: 6px;
}

.contentWrapper {
    align-self: center;
    display: flex;
    margin-top: 62px;
    width: 100%;
    max-width: 1368px;
    gap: 40px 54px;
    flex-wrap: wrap;
    padding: 20px;
    max-height: auto;
}

.termsContent {
    color: #000;
    flex-grow: 1;
    width: 1293px;
    flex-basis: auto;
    font: 400 18px/28px SF Pro Display, -apple-system, Roboto, Helvetica, sans-serif;
}

.scrollBarContainer {
    align-self: start;
    display: flex;
    gap: 10px;
    justify-content: start;
    padding: 4px;
}

.scrollBarWrapper {
    display: flex;
    width: 100%;
    padding-bottom: 20px;
    flex-direction: column;
    flex: 1;
    flex-basis: 0%;
}

.scrollBarTrack {
    border-radius: 8px;
    background-color: #122577;
    display: flex;
    height: 103px;
}

.textBold {
    font-family: Sequel Sans, sans-serif;
    font-weight: 600;
    font-size: 20px;
}

.textRegular {
    font-family: Sequel Sans, sans-serif;
    font-weight: 310;
}

.textLight {
    font-family: Sequel Sans, sans-serif;
    font-weight: 310;
    font-size: 16px;
}

.sectionTitle {
    margin: 24px 0 12px;
}

.sectionContent {
    margin-bottom: 16px;
}

.effectiveDate {
    margin-bottom: 24px;
}

.welcome {
    margin-bottom: 32px;
}

@media (max-width: 991px) {
    .header {
        max-width: 100%;
        padding: 0 20px;
    }

    .headerWrapper {
        max-width: 100%;
    }

    .sectionLabel {
        max-width: 100%;
    }

    .mainTitle {
        max-width: 100%;
    }

    .subtitle {
        max-width: 100%;
    }

    .contentWrapper {
        max-width: 100%;
        margin-top: 40px;
    }

    .termsContent {
        max-width: 100%;
    }

    .scrollBarContainer {
        display: none;
    }

    .scrollBarWrapper {
        padding-bottom: 100px;
    }

    .container {
        margin-top: 80px;
    }

    .childContent{
        padding: 20px;
    }
}