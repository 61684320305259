.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f8f9ff;
  position: relative;
}

.childContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  /* width: 75%; */
  margin-top: 3rem;
  padding: 0px 140px;
  padding-top: 30px;
}

.headingShadow {
  position: relative;
  align-self: flex-start;
  margin-bottom: -25px;
  color: #122577;
  font-size: 7vw;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  opacity: 0.05;
}

.containerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 3%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(0, 0, 0, 0.05);
  z-index: 1;
  gap: 60px;
  min-height: 200px;
}

.childContainer:hover .containerContent1 {
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(254, 212, 98, 0.3);
}
.childContainer:hover .containerContent2 {
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(255, 132, 124, 0.3);
}

.childContainer:hover .containerContent3 {
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(255, 138, 0, 0.3);
}

.childContainer:hover .containerContent4 {
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(255, 138, 0, 0.3);
}

.childContainer:hover .containerContent5 {
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(112, 110, 230, 0.3);
}
.childContainer:hover .containerContent6 {
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(207, 118, 221, 0.3);
}

.partner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  /* height: 5vh; */
  /* padding: 8px 20px; */
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  min-width: 13rem;
  padding: 7px 0px;
  background-color: rgba(254, 212, 98, 0.1);
}

.contentImageRight {
  min-width: 300px;
  height: 100%;
  width: 40%;
  transform: scale(1.2);
  margin-right: 3%;
}

.contentImageLeft {
  min-width: 300px;
  height: 100%;
  width: 40%;
  transform: scale(1.2);
  margin-left: 3%;
}

.contentText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 25px;
  width: 50%;
  padding: 0px 2%;
}

.contentTextHeader {
  color: #122577;
  font-size: clamp(26px, 2.4vw, 32px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  word-wrap: break-word;
}

.contentTextDetail {
  color: #5a5a5a;
  font-size: clamp(14px, 2.4vw, 17px);
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.contentTextFooter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.link {
  width: fit-content;
  text-align: center;
  color: black;
  text-decoration: none;
}

@media (min-width: 1500px) {
  .containerContent {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 0px 40px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 34.854px 29.626px 48.34px 0px rgba(0, 0, 0, 0.05);
    z-index: 1;
    gap: 60px;
    min-height: 200px;
    height: 38rem;
  }

  .contentImageRight,
  .contentImageLeft {
    min-width: 300px;
    height: auto;
    width: auto;
    transform: scale(1.3);
    padding: 1rem 0rem;
  }
  .contentImageRight {
    margin-left: 3rem;
  }
  .contentImageLeft {
    margin-right: 3rem;
  }

  .contentTextHeader {
    font-size: 2.5vw;
  }

  .contentTextDetail {
    font-size: 1.5vw;
    line-height: 32px;
  }

  .partner {
    font-size: 1.5rem;
    padding: 10px 20px;
    min-width: 16rem;
  }
}

@media (max-width: 1260px) {
  .partner {
    font-size: 0.8rem;
    min-width: 10rem;
  }

  .contentTextHeader {
    font-size: clamp(22px, 2vw, 27px);
  }
  .contentTextDetail {
    font-size: clamp(14px, 1vw, 16px);
    line-height: 21px;
  }
}

@media (max-width: 1000px) {
  .childContainer {
    padding: 0px 50px;
    padding-top: 30px;
    height: fit-content;
  }

  .containerContent {
    padding: 2rem;
    margin-top: 0.5rem;
  }

  .contentText {
    width: 60%;
  }

  /* .contentTextHeader {
    font-size: 3vw;
  } */

  .contentImage {
    width: 45%;
    height: 100%;
  }
}

@media (max-width: 870px) {
  .footerContent {
    display: flex;
    height: auto;
    /* width: 1000px; */
    justify-content: space-between;
    color: #666;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22.4px;
    position: relative;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .container {
    padding-top: 3rem;
  }

  .childContainer {
    /* margin-top: 1rem; */
    padding: 0px 5%;
  }

  .headingShadow {
    font-size: 10vw;
    margin-bottom: -20px;
  }

  .containerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 40px 40px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 34.854px 29.626px 48.34px 0px rgba(0, 0, 0, 0.05);
    z-index: 1;
    gap: 60px;
    min-height: 200px;
  }

  .containerContent2,
  .containerContent4,
  .containerContent6 {
    display: flex;
    flex-direction: column-reverse;
  }

  .contentText {
    width: 100%;
    margin-top: -2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .healthcare_Header {
    text-align: left;
    width: 100%;
  }

  .contentTextHeader {
    font-size: 5vw;
  }

  .contentTextDetail {
    font-size: 1.4rem;
    line-height: 24px;
  }

  /* .contentTextDetail {
    font-size: 4vw;
  } */

  .contentImageLeft,
  .contentImageRight {
    min-width: 0px;
    width: 80%;
    height: 100%;
    transform: scale(1);
  }

  .contentTextFooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
  }

  .partner {
    font-size: 1.2rem;
  }
}

@media (max-width: 550px) {
  .containerContent {
    padding: 5%;
  }

  .contentImageRight,
  .contentImageLeft {
    width: 100%;
    height: 100%;
  }

  .partner {
    font-size: 14px;
  }

  .headingShadow {
    margin-bottom: -20px;
  }
  .contentTextHeader {
    font-size: 28px;
  }

  .contentTextDetail {
    font-size: 14px;
    /* line-height: 20px; */
  }
}

@media (max-width: 400px) {
  .headingShadow {
    margin-bottom: -15px;
  }

  /* .contentTextHeader {
    font-size: 28px;
  } */

  /* .contentTextDetail {
    font-size: 1.1rem;
    line-height: 24px;
  } */
}
