.footer {
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 40px 40px 24px;
}

.gradientBg {
    background-color: #061244;
    position: absolute;
    z-index: 0;
    min-height: 666px;
    width: 100%;
    inset: 0;
}

.topSection {
    border-radius: 16px;
    background-color: #fff;
    z-index: 1;
    width: 100%;
    padding: 32px 40px;
}

.contentGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.logoColumn {
    display: flex;
    flex-direction: column;
}

.mainLogo {
    width: 142px;
    height: auto;
}

.secondaryLogo {
    width: 175px;
    height: auto;
    margin-top: 20px;
}

.menuContainer {
    display: flex;
    gap: 16px;
}

.menuSection {
    flex: 1;
}

.menuTitle {
    font-family: 'Sequel Sans', sans-serif;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    color: #767575;
    margin: 0 0 24px;
}

.menuList {
    list-style: none;
    padding: 0;
    margin: 0;
}


.menuItem {
    margin-top: 4px;
    list-style: none;
}

.menuItem a {
    color: rgb(27, 24, 25);
    text-decoration: none;
    font-family: 'Sequel Sans', sans-serif;
    font-size: 16px;
    font-weight: 405;
    transition: color 0.2s;
}

.menuItem a:hover {
    color: rgb(6, 18, 68);
}

.contactColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.callRequestButton {
    border-radius: 8px;
    background-color: rgb(18, 37, 119);
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-family: 'Sequel Sans', sans-serif;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.callRequestButton:hover {
    background-color: rgb(6, 18, 68);
}

.contactInfo {
    margin-top: 16px;
    text-align: right;
}

.phoneNumber,
.email {
    display: block;
    color: rgb(27, 24, 25);
    text-decoration: none;
    font-family: 'Sequel Sans', sans-serif;
    font-size: 12px;
    line-height: 1.5;
}

.bottomRow {
    display: flex;
    margin-top: 72px;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
}

.socialSection {
    display: flex;
    gap: 8px;
}

.socialSection,
.addressText,
.backToTopSection {
    flex: 1;
}

.backToTopSection {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
}
.socialLink {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: rgb(6, 18, 68);
    transition: background-color 0.2s;
}

.socialLink:hover {
    background-color: rgb(18, 37, 119);
}

.socialIcon {
    width: 16px;
    height: 16px;
}

.addressText {
    flex: 1;
    font-style: normal;
    font-family: 'Sequel Sans', sans-serif;
    font-size: 16px;
    line-height: 16px;
    color: rgb(27, 24, 25);
    margin-left: -10px;

}

.backToTopButton {
    display: flex;
    align-items: center;
    gap: 16px;
    background: none;
    border: none;
    cursor: pointer;
    font-family: 'Sequel Sans', sans-serif;
    font-size: 12px;
    color: rgb(27, 24, 25);
    box-shadow: none;
}

.backToTopButton:hover {
    transform: scale(1.05);
    box-shadow: none;
}

.subscriptionSection {
    z-index: 1;
    display: flex;
    margin-top: 48px;
    width: 100%;
    gap: 16px;
    align-items: center;
}

.subscriptionContent {
    flex: 1;
    color: #fff;
}

.newsletterTitle {
    font-family: 'Sequel Sans', sans-serif;
    font-size: 36px;
    font-weight: 420;
    letter-spacing: -0.72px;
    margin: 0;
}

.newsletterDescription {
    font-family: 'Sequel Sans', sans-serif;
    font-size: 24px;
    line-height: 33px;
    margin-top: 16px;
}

.subscriptionForm {
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    max-width: 100%;
}

.subscriptionForm h6{
    color:#fff
}

.inputWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    padding: 8px 8px 8px 32px;
    margin-top: 8px;
    /* max-width: 800px; */
    width: 100%;
}

.emailInput {
    flex: 1;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-family: 'Sequel Sans', sans-serif;
    font-size: 20px;
    max-width: calc(100% - 120px);
    box-sizing: border-box;
}

.emailInput:focus {
    box-shadow: none !important;
}

.emailInput::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.subscribeButton {
    border-radius: 56px;
    background-color: #fff;
    color: rgb(6, 18, 68);
    border: none;
    padding: 14px 26px;
    font-family: 'Sequel Sans', sans-serif;
    font-size: 16px;
    font-weight: 420;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;
    box-sizing: border-box;
    width: 120px;
}

.subscribeButton:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

.copyrightSection {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    margin-top: 48px;
}

@media (max-width: 991px) {
    .footer {
        padding: 20px 20px;
    }

    .topSection {
        padding: 20px;
    }

    .contentGrid {
        grid-template-columns: 1fr;
        gap: 32px;
    }

    .contactColumn {
        align-items: flex-start;
    }

    .contactInfo {
        text-align: left;
    }

    .bottomRow {
        margin-top: 40px;
    }

    .subscriptionSection {
        flex-direction: column;
    }
}

@media (max-width: 640px) {
    .menuContainer {
        flex-direction: column;
        gap: 32px;
    }

    .inputWrapper {
        flex-direction: row;
        padding: 8px;
    }

    .emailInput {
        padding: 8px 16px;
        font-size: 14px;
    }

    .subscribeButton {
        white-space: nowrap;
        padding: 10px 20px;
        font-size: 14px;
    }
}