.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 12px rgba(87, 36, 36, 0.1);
  display: flex;
  min-height: 84px;
  width: 100%;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 22px 72px;
}

.brandSection {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgba(18, 37, 119, 1);
  white-space: nowrap;
  justify-content: start;
  margin: auto 0;
  font: 420 24px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
  cursor: pointer;
}

.brandLogo {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  border-radius: 8px;
  align-self: stretch;
  margin: auto 0;
}

.brandName {
  align-self: stretch;
  margin: auto 0;
}

.navLinks {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 24px;
  color: rgba(10, 10, 10, 1);
  text-align: center;
  letter-spacing: -0.54px;
  justify-content: start;
  flex-wrap: wrap;
  margin: auto 0;
  font: 310 18px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.navLink {
  align-self: stretch;
  margin: auto 0;
  cursor: pointer;
  transition: color 0.2s ease;
  font-size: 1.1rem;
}

.navLink:hover, .navLink:focus {
  color: rgba(18, 37, 119, 1);
  outline: none;
}

.profileSection {
  position: relative;
}

.profileContainer {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: start;
  margin: auto 0;
  cursor: pointer;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.profileContainer:hover, .profileContainer:focus {
  /* background-color: rgba(0, 0, 0, 0.05); */
  outline: none;
}

.avatarWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  overflow: hidden;
  align-self: stretch;
  position: relative;
  aspect-ratio: 1;
  width: 32px;
  margin: auto 0;
  padding: 23px 6px 0;
}

.avatarImage {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.onlineIndicator {
  position: relative;
  border-radius: 4px;
  border: 1.5px solid var(--White, #fff);
  background: var(--Success-500, #12b76a);
  z-index: 10;
  display: flex;
  width: 100%;
  height: 11px;
}

.userInfo {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: auto 0;
}

.userName {
  color: var(--Gray-700, #344054);
  font: 415 14px/1 Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.userEmail {
  color: var(--Gray-600, #475467);
  font: 400 12px Inter, sans-serif;
}

.dropdownIcon {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  justify-content: start;
  height: 100%;
  width: 24px;
  padding: 10px 0 10px 4px;
}

.dropdownImage {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
  align-self: stretch;
  margin: auto 0;
}

.menuContainer {
  position: absolute;
  top: 100%;
  right: 10;
  z-index: 1000;
  margin-top: 8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 220px;
}

.menuContent {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  position: relative;
}

.menuItem:hover, .menuItem:focus {
  background-color: rgba(0, 0, 0, 0.05);
  outline: none;
}

.menuIcon {
  width: 20px;
  height: 20px;
}

.authContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
  justify-content: flex-end;
  font: 18px Sequel Sans, sans-serif;
}

.authButton {
  align-self: stretch;
  margin: auto 0;
  font-weight: 410;
  letter-spacing: 0.54px;
  padding: 12px 20px;
  border: none;
  border-radius: 100px;
  transition: background-color 0.3s, color 0.3s;
}

.loginButton {
  color: rgba(10, 10, 10, 1);
  width: 54px;
}

.signupButton {
  width: 94px;
  border-radius: 100px;
  background-color: rgba(18, 37, 119, 1);
  min-height: 44px;
  gap: 8px;
  overflow: hidden;
  color: rgba(255, 255, 255, 1);
  font-weight: 405;
  letter-spacing: 0.18px;
  padding: 12px 20px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.active {
  background-color: rgba(18, 37, 119, 1);
  color: rgba(255, 255, 255, 1);
}

.nonActive {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(10, 10, 10, 1);
}

.activeIndicator {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 24px;
  background-color: rgba(18, 37, 119, 1);
  border-radius: 2px;
}

.activeNavLink {
  font-weight: bold;
  color: rgba(18, 37, 119, 1);
  position: relative;
}

.activeNavLink::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: rgba(18, 37, 119, 1);
}

.logoutItem {
  color: #EB4C4C;
}

.mobileMenuButton {
  display: none;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.mobileMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  padding: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.mobileMenuItem {
  padding: 12px 16px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .navbar {
    padding: 16px 24px;
  }
  
  .navLinks {
    display: none;
  }
  
  .mobileMenuButton {
    display: block;
  }
  
  .mobileMenu {
    display: block;
  }

  .profileSection{
    display: none;
  }
}

@media (max-width: 640px) {
  .profileContainer {
    display: none;
  }
  .menuContainer{
    display: none;
  }
}