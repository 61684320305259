.faqContainer {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 60px;
}

.heroSection {
    background-image: url('../../../../public/Assets/Modules/Home/FAQ/faqback.svg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 700px;
    background-color: #061244;
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 100px;
    /* display: flex;
        flex-direction: column;
        position: relative;
        min-height: auto;
        width: 100%;
        justify-content: center;
        padding: 89px 80px;
        background-color: #1a365d;
        background-image: url('../../../../public/Assets/Modules/Home/FAQ/faqback.svg');
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 1rem; */
}

.contentWrapper {
    display: flex;
    width: 100%;
    max-width: 1219px;
    flex-direction: column;
}

.headerContent {
    align-self: center;
    display: flex;
    width: 692px;
    max-width: 100%;
    flex-direction: column;
}

.headerText {
    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: Sequel Sans, sans-serif;
    font-weight: 410;
    text-align: center;
}

.label {
    color: #bfbfbf;
    font-size: 24px;
    line-height: 52px;
    letter-spacing: 0.48px;
    align-self: center;
}

.title {
    color: #fff;
    font-size: 36px;
    font-weight: 420;
    line-height: 1;
    margin-top: 6px;
}

.subtitle {
    color: #d7d7d7;
    font-size: 20px;
    line-height: 38px;
    margin-top: 6px;
}

.searchContainer {
    align-self: center;
    display: flex;
    margin-top: 24px;
    width: 417px;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 4px 0;
}

.searchInput {
    align-items: center;
    border-radius: 100px;
    border: 1px solid #d0d5dd;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    width: 100%;
    gap: 8px;
    overflow: hidden;
    padding: 4px 4px 4px 16px;
}

.searchField {
    flex: 1;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 8px;
    background: transparent;
}

.searchField:focus {
    box-shadow: none !important;
}

.searchIcon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 20px;
}

.searchButton {
    border-radius: 100px;
    background-color: #122577;
    min-height: 40px;
    color: #fff;
    padding: 0 20px;
    font: 405 18px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.searchButton:hover {
    background-color: #1a32a0;
}

.searchButton:focus-visible {
    outline: 2px solid #fff;
    outline-offset: 2px;
}

.visuallyHidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.tagContainer {
    display: flex;
    margin-top: 44px;
    min-height: 48px;
    width: 100%;
    align-items: start;
    gap: 12px;
    color: #fff;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    font: 415 16px/1 Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.tag {
    border-radius: 100px;
    min-height: 48px;
    padding: 13px 24px;
    border: 1px solid #fff;
    cursor: pointer;
    background: transparent;
    color: #fff;
    transition: all 0.2s ease;
}

.tag:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.activeTag {
    color: #122577;
    background-color: #fff !important;
}

.faqContent {
    align-self: center;
    margin-top: 83px;
    width: 100%;
    max-width: 1194px;
    padding: 0 20px;
}

.faqGrid {
    gap: 20px;
    display: flex;
}

.faqHeader {
    width: 34%;
}

.faqHeaderTitle {
    color: #0a0a0a;
    font-size: 32px;
    font-weight: 420;
    /* line-height: 2; */
    font-family: Sequel Sans, sans-serif;
    margin-bottom: 12px;
}

.faqHeaderDescription {
    color: #828181;
    font-size: 16px;
    font-weight: 410;
    line-height: 25px;
    margin-top: 7px;
    font-family: Sequel Sans, sans-serif;
}

.faqList {
    width: 66%;
    margin-left: 20px;
}

.faqItem {
    display: flex;
    gap: 16px;
    padding: 32px 0;
    border-bottom: 1px solid #e8e8e8;
}

.faqItem:last-child {
    border-bottom: none;
}

.faqIcon {
    width: 35px;
    height: 35px;
    border-radius: 18px;
    object-fit: contain;
}

.faqItemContent {
    flex: 1;
}

.faqQuestion {
    color: #0a0a0a;
    font-size: 16px;
    font-weight: 415;
    line-height: 1;
    margin: 0 0 6px 0;
}

.faqAnswer {
    color: #828181;
    font-size: 14px;
    font-weight: 405;
    line-height: 1;
    letter-spacing: 0.42px;
    margin: 0;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ebefff;
    margin-right: 10px;
}

@media (max-width: 991px) {
    .heroSection {
        padding: 40px 20px;
    }

    .headerContent {
        width: 100%;
    }

    .faqGrid {
        flex-direction: column;
    }

    .faqHeader,
    .faqList {
        width: 100%;
        margin-left: 0;
    }

    .faqHeader {
        margin-bottom: 40px;
    }

    .searchContainer {
        width: 100%;
    }

    .tag {
        padding: 10px 20px;
    }

    .faqContainer {
        margin-top: 100px;
    }
}