/* general */

input:focus,
textarea:focus,
select:focus {
  outline: none;
  border: none;
  box-shadow: 0 0 3px rgb(108, 131, 138);
}

.cont {
  width: 100%;
  height: 95%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.top {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 87%;
  height: 105px;
  margin-bottom: 10px;
}

.top__img {
  width: 152px;
  height: 32px;
  gap: 0px;
  /* opacity: 0px; */
}

.top__head {
  /* border: 1px solid; */
  width: 100%;
  height: 43px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 42.96px;
  text-align: left;
  color: #00183d;
}

.middle {
  display: flex;
  flex-direction: column;
  width: 87%;
  gap: 30px;
}

.middle__continueWithGoogle {
  width: 100%;
  height: 56px;
  border-radius: 8px;
  /* opacity: 0px; */
  background: #fffaeb;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.middle__continueWithGoogle__icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

.middle__continueWithGoogle > h3 {
  width: 178px;
  height: 21px;
  gap: 0px;
  /* opacity: 0px; */
  margin-left: 10px;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.48px;
  text-align: left;
}

.middle__divider {
  position: relative;
  width: 100%;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle__divider__line {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid #cccccc;
}

.middle__divider__content {
  position: absolute;
  width: auto;
  height: auto;
  padding: 0 5px;
  /* opacity: 1; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  text-align: center;
  background: white;
  color: #949494;
}

.middle__entries {
  width: 100%;
  height: 243px;
  gap: 30px;
  /* opacity: 0px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 55px;
}

.middle__entries__field {
  width: 100%;
  height: 77px;
  gap: 12px;
  /* opacity: 0px; */
  display: flex;
  flex-direction: column;
  position: relative;
}

.middle__entries__field > input {
  width: 100%;
  height: 46px;
  padding-left: 10px;
  border-style: none;
  background: #f2f2f2;
  gap: 8px;
  border-radius: 8px;
  /* opacity: 0px; */
}

.middle__entries__field__head {
  width: 100%;
  height: 19px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: left;
}

.middle__entries__field__cover {
  width: 100%;
  height: 46px;
  gap: 8px;
  position: relative;
}

.middle__entries__field__cover > input {
  width: 100%;
  height: 46px;
  padding-left: 10px;
  border-style: none;
  background: #f2f2f2;
  gap: 8px;
  border-radius: 8px;
  /* opacity: 0px; */
}

.middle__entries__field__eyeIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.middle__entries__rememberMe {
  width: 100%;
  height: 27px;
  gap: 0px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 35px;
  /* opacity: 0px; */
}

.middle__entries__rememberMe__left {
  width: 60%;
  height: 24px;
  gap: 12px;
  /* opacity: 0px; */
  display: flex;
  align-items: center;
}

.middle__entries__rememberMe__left > input {
  width: 24px;
  height: 24px;
  padding: 2px;
  gap: 0px;
  border-radius: 6px;
  /* opacity: 0px; */
  cursor: pointer;
}

.middle__entries__rememberMe__left > label {
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  text-align: left;
}

.middle__entries__rememberMe__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  height: 27px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.09px;
  text-align: right;
  color: #1a73e8;
  cursor: pointer;
}

.error {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  color: red;
  font-size: 0.9rem;
  height: auto;
  border-radius: 5px;
  width: auto;
  padding: 5px;
}

.bottom {
  width: 87%;
  height: 113px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.bottomButton {
  border-style: none;
  width: 100%;
  height: 56px;
  padding: 16px 30px 16px 30px;
  gap: 8px;
  border-radius: 8px;
  /* opacity: 0px; */
  background: #fed462;
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.bottomButton.disabledBtn {
  background: rgba(176, 175, 175, 0.707);
}

.bottom__signUp {
  display: flex;
  width: 100%;
  height: 27px;
  gap: 8px;
  /* opacity: 0px; */
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}

.bottom__signUp__left {
  /* border: 1px solid; */
  width: 63%;
  height: 27px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 400;
  /* line-height: 27px; */
  letter-spacing: 0.01em;
  text-align: right;
}

.bottom__signUp__right {
  width: 35%;
  height: 27px;
  gap: 0px;
  /* opacity: 0px; */
  font-family: "SF Pro Display", sans-serif;
  font-size: 18px;
  font-weight: 500;
  /* line-height: 21.48px; */
  text-align: left;
  color: #1a73e8;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .top {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .top__head {
    font-size: 33px;
  }

  .middle {
    margin-bottom: 30px;
  }

  .bottom {
    margin-bottom: 30px;
  }
}

@media (max-width: 700px) {
  .top,
  .middle,
  .bottom {
    width: 90%;
  }

  .top {
    height: 120px;
  }

  .cont {
    height: 98%;
  }
}

@media (max-width: 600px) {
  .top,
  .middle,
  .bottom {
    width: 96%;
  }
}
