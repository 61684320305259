.chatButton {
  position: fixed;
  bottom: 79px;
  right: 0;
  z-index: 99;
  display: flex;
  padding: 16px 20px;
  align-items: center;
  gap: 16px;
  border-radius: 8px 0px 0px 8px;
  background: #8c0f37;
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.1);
}
