.sponsorsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 40px 20px;
}

.sponsorsTitle {
  color: rgba(102, 102, 102, 1);
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  font: 415 12px/1 Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.sponsorsGrid {
  display: flex;
  margin-top: 16px;
  align-items: center;
  gap: 16px;
  overflow: hidden;
  justify-content: start;
  cursor: grab;
  overflow-x: auto;
  transition: transform 0.3s ease;
  scrollbar-width: none;
    -ms-overflow-style: none;
}

.sponsorsGrid::-webkit-scrollbar {
  display: none;
}

.sponsorsGrid:active {
  cursor: grabbing;
}

.sponsorLogo {
  width: 100%;
  height: 100px;
  flex-shrink: 0;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 768px) {
  .sponsorLogo {
    height: 30px;
  }
  
}