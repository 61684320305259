.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  margin-top: 20px;
}

.label {
  color: rgba(4, 27, 45, 1);
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.28px;
  font-family: Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.required {
  color: rgba(220, 38, 38, 1);
  margin-left: 2px;
}

.iconStyle{
  width: 25px;
  height: 25px;
  margin: 18px 0;
}

.phoneInput {
  display: flex;
  border-radius: 8px;
  border: 1px solid #eee;
  background-color: #fff;
  overflow: hidden;
}

.countryCode {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px 12px;
  border-right: 1px solid #eee;
  background-color: #fafafa;
}

.flag {
  width: 18px;
  height: 18px;
  object-fit: contain;
}

.chevron {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.phoneNumber {
  flex: 1;
  border: none;
  padding: 10px 14px;
  font-size: 16px;
  color: rgba(4, 27, 45, 1);
}

.phoneNumber:focus {
  outline: none;
}

.sendCodeStyle{
    color: #122577;
    font-weight: 550;
}

.error {
    position: relative;
    left: 10%;
  color: red;
  font-size: 1rem;
  height: auto;
  border-radius: 5px;
  width: auto;
  padding: 5px;
  align-self: flex-start;
}

.info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    gap: 6px;
    width: 100%;
    height: auto;
    border-radius: 8px;
    flex-direction: column;
}

.otpInput{
    justify-content: space-between;
}

.timeStyle{
    color: red;
}