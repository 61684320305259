.navbarParent {
  display: flex;
  justify-content: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.introNavbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 20px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 20px 40px 40px rgba(0, 0, 0, 0.1);
  z-index: 999;
  min-height: 80px;
  flex-wrap: wrap;
  line-height: 10px;
  transition: all 1.5s;
  gap: 0rem;
  width: 80%;
}

.sectionOptions {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
}

.fullWidth {
  width: 100%;
  top: 0px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
}

.option1 {
  position: absolute;
  opacity: 0;
  transition: all 1.5s;
  cursor: pointer;
}

.visible {
  position: relative;
  opacity: 1;
  display: block;
}

.sticky {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a9a9a9;
  min-width: fit-content;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  height: 80px;
}

.sectionOptions .option {
  margin: 0 10px;
  cursor: pointer;
  /* transition: all 0.2s ease-out; */
}

.sectionOptions .option.active {
  color: #122577;
  border-bottom: 3px solid #122577;
}

.sectionOptions .option:hover {
  color: rgb(18, 37, 119, 0.7);
  border-bottom: 3px solid rgb(18, 37, 119, 0.3);
}

/* <------------------dropdown menu ------------------> */

.toggle_btn {
  /* margin-top: -0.9rem; */
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
}
.dropdown_menu {
  display: none;
  position: absolute;
  right: 0px;
  left: 0px;
  top: 70px;
  height: 100px;
  border-radius: 0px;
  padding: 2%;
  transition: all 0.5s linear;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 20px 20px 40px 0px rgba(0, 0, 0, 0.05);
backdrop-filter: blur(25px);
}

.dropdown_option {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  min-width: fit-content;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  height: 50px;
}

.dropdown_option:hover{
  font-weight: 700;
}

@media (max-width: 1000px) {
  .option {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .fullWidth {
    display: flex;
    justify-content: space-between;
    padding: 0rem 1rem;
  }

  .sectionOptions {
    display: none;
  }

  .toggle_btn {
    display: block;
  }

  .dropdown_menu.open {
    margin-top: 10px;
    height: auto;
    display: block;
  }
}

@media (max-width: 550px) {
  .introNavbar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 1rem;
    padding-left: 1rem;
    top: 0px;
    width: 100%;
    min-height: 80px;
  }

  .option1 {
    position: relative;
    opacity: 1;
    transition: all 1.5s;
    cursor: pointer;
  }

  .toggle_btn {
    display: block;
  }

  .topSectionContent {
    gap: 2vh;
  }
}
