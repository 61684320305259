.blogListing {
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 60px;
}

.header {
    background: rgba(6, 18, 68, 1);
    padding: 96px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerContent {
    max-width: 1219px;
    width: 100%;
}

.headerText {
    max-width: 692px;
    margin: 0 auto;
    text-align: center;
}



.headerLabel {
    color: #bfbfbf;
    font: 410 24px/52px 'Sequel Sans', sans-serif;
    letter-spacing: 0.48px;
}

.headerTitle {
    color: #fff;
    font: 420 36px/1 'Sequel Sans', sans-serif;
    margin-top: 6px;
}

.headerDescription {
    color: #d7d7d7;
    font: 400 20px/38px 'Sequel Sans', sans-serif;
    margin-top: 6px;
}

.searchForm {
    max-width: 417px;
    margin: 24px auto;
    padding: 4px 0;
}

.visuallyHidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.searchInput {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #d0d5dd;
    border-radius: 100px;
    padding: 4px 4px 4px 16px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.searchIcon {
    width: 20px;
    height: 20px;
}

.searchField {
    flex: 1;
    border: none;
    padding: 0 8px;
    font: 400 16px Inter, sans-serif;
    color: #667085;
    min-width: 240px;
    border: none;
    outline: none;
}

.searchField:focus {
    border: none;
    outline: none;
    box-shadow: none !important;
}

.searchButton {
    background: rgba(18, 37, 119, 1);
    color: #fff;
    border: none;
    border-radius: 100px;
    padding: 0 20px;
    height: 40px;
    font: 405 18px 'Sequel Sans', sans-serif;
    letter-spacing: 0.18px;
    cursor: pointer;
}

.categoryNav {
    margin-top: 48px;
}

.categoryList {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    
}

.categoryList li{
    list-style: none;
}

.categoryButton {
    border: 1px solid #fff;
    border-radius: 100px;
    padding: 13px 24px;
    color: #fff;
    font: 415 16px 'Sequel Sans', sans-serif;
    background: transparent;
    cursor: pointer;
    min-height: 48px;
    min-width: 160px;
    transition: all 0.2s ease;
}

.categoryButton:hover {
    background: rgba(255, 255, 255, 0.1);
}

.categoryButton.active {
    background: #fff;
    color: rgba(18, 37, 119, 1);
}

.mainContent {
    max-width: 1216px;
    margin: 0 auto;
    padding: 72px 20px;
    width: 100%;
}

.sectionTitle {
    color: #101828;
    font: 420 26px/1 'Sequel Sans', sans-serif;
    margin-bottom: 32px;
}

.blogGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 32px;
    margin-bottom: 72px;
}

.blogCard {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease;
}

.blogCard:hover {
    transform: translateY(-4px);
}

.blogImageWrapper {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.blogImage {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.blogCategory {
    position: absolute;
    top: 16px;
    left: 16px;
    background: #fff;
    border-radius: 100px;
    padding: 8px 16px;
    font: 405 16px 'Sequel Sans', sans-serif;
    color: rgba(18, 37, 119, 1);
}

.blogContent {
    padding: 24px;
}

.blogMeta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.authorInfo {
    display: flex;
    align-items: center;
    gap: 12px;
}

.authorAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.authorName {
    color: #101828;
    font: 410 14px 'Sequel Sans', sans-serif;
}

.blogDate {
    color: #667085;
    font: 400 14px 'Sequel Sans', sans-serif;
}

.blogTitle {
    color: #101828;
    font: 420 20px/1.4 'Sequel Sans', sans-serif;
    margin-bottom: 8px;
}

.blogDescription {
    color: #475467;
    font: 400 16px/1.6 'Sequel Sans', sans-serif;
    margin-bottom: 24px;
}

.readMoreButton {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(18, 37, 119, 1);
    font: 415 16px 'Sequel Sans', sans-serif;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: color 0.2s ease;
    text-decoration: underline;
}

.readMoreButton:hover {
    color: rgba(18, 37, 119, 0.8);
    transform: scale(1.05);
    box-shadow: none;
}



.readMoreIcon {
    width: 20px;
    height: 20px;
    transition: transform 0.2s ease;
}

.readMoreButton:hover .readMoreIcon {
    transform: translateX(4px);
}

.desktopPagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-top: 1px solid #eaecf0;
}

.paginationButton {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #475467;
    font: 600 14px Inter, sans-serif;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.2s ease;
}

.paginationButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.paginationButton:not(:disabled):hover {
    color: #101828;
}

.paginationNumbers {
    display: flex;
    gap: 4px;
}

.pageNumber {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: none;
    background: none;
    color: #667085;
    font: 500 14px Inter, sans-serif;
    cursor: pointer;
    transition: all 0.2s ease;
}

.pageNumber:hover:not(.active) {
    background: #f9fafb;
}

.pageNumber.active {
    background: #edf0ff;
    color: rgba(18, 37, 119, 1);
    font-weight: 600;
}

.mobilePagination {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    max-width: 343px;
    margin: 0 auto;
}

.mobilePageButton {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: none;
    background: #f9fafb;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease;
}

.mobilePageButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.mobilePageButton:not(:disabled):hover {
    background: #edf0ff;
}

.mobilePageInfo {
    color: #344054;
    font: 500 14px Inter, sans-serif;
}

@media (max-width: 991px) {
    .header {
        padding: 64px 20px;
    }

    .blogGrid {
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    }
    .blogListing{
        margin-top: 100px;
    }
}

@media (max-width: 640px) {
    .desktopPagination {
        display: none;
    }

    .mobilePagination {
        display: flex;
    }

    .categoryButton {
        min-width: 120px;
        padding: 10px 16px;
    }
}