.container {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  margin: 0 auto;
  padding: 40px 40px;
}

.header {
  align-self: start;
  display: flex;
  flex-direction: row;
  font-family: Sequel Sans, sans-serif;
  justify-content: space-between;
  margin-bottom: 36px;
  width: 100%;
}

.title {
  color: rgba(16, 3, 21, 0.96);
  font: 425 48px/1 Sequel Sans, sans-serif;
  letter-spacing: -0.36px;
}

.titleHighlight {
  color: rgba(18, 37, 119, 1);
  font-weight: 415;
}

.subtitle {
  color: rgba(167, 167, 167, 0.96);
  font: 415 24px/53px Sequel Sans, sans-serif;
  margin-top: 8px;
}

.content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 38px;
  justify-content: space-between;
}

.featureSection {
  display: flex;
  flex: 1;
  min-width: 240px;
  align-items: flex-start;
  gap: 40px 64px;
  color: rgba(102, 102, 102, 1);
  flex-wrap: wrap;
  font: 405 20px/32px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.featureImage {
  aspect-ratio: 1.4;
  object-fit: contain;
  object-position: center;
  width: 40%;
  min-width: 240px;
  border-radius: 8px;
}

.featureContent {
  display: flex;
  flex-direction: column;
  width: 40%;
  min-width: 240px;
  padding-top: 26px;
}

.featureIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
}

.featureDetails {
  margin-top: 24px;
}

.featureTitle {
  color: rgba(18, 37, 119, 1);
  font: 415 24px/1 Sequel Sans, sans-serif;
  letter-spacing: 0.48px;
  margin-bottom: 12px;
}

.featureDescription {
  margin-bottom: 12px;
}

.featureList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.featureListItem {
  list-style-type: disc
}

.pagination {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: flex-end;
}

.paginationDot {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  background-color: rgba(211, 204, 228, 1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.paginationDotActive {
  composes: paginationDot;
  height: 42px;
  background-color: rgba(37, 25, 65, 1);
}

.supportButton {
  border-radius: 100px;
  background-color: rgba(18, 37, 119, 1);
  box-shadow: -4px -4px 12px rgba(254, 254, 254, 0.17);
  display: flex;
  max-width: 171px;
  flex-direction: column;
  overflow: hidden;
  color: rgba(255, 255, 255, 1);
  white-space: nowrap;
  justify-content: center;
  padding: 10px 22px;
  font: 410 22px/40px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
  margin:20px
}

.buttonContent {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: start;
}

.supportIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 36px;
  align-self: stretch;
  margin: auto 0;
}

.supportText {
  align-self: stretch;
  margin: auto 0;
}

@media (max-width: 991px) {
  .content {
    flex-direction: column;
    align-items: center;
  }
  
  .pagination {
    margin-top: 20px;
    flex-direction: row; /* Change to horizontal direction */
    gap: 8px; /* Adjust gap as needed */
    align-self: cente;
  }

  .paginationDotActive {
    width: 42px; /* Adjust width to match height */
    height: 8px; /* Keep height consistent with other dots */
  }
  
  .featureSection {
    justify-content: center;
  }
  
  .featureImage,
  .featureContent {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .supportButton {
    display: none; /* Hide the support button on mobile devices */
  }
}