.App {
  text-align: center;
  position: relative;
}

:root {
  --primary: #252762;
}

.fade-enter {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 2000ms, transform 2000ms;
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 2000ms, transform 2000ms;
}
.fade-exit {
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active {
  opacity: 0;
  transform: scale(3);
  transition: opacity 1000ms, transform 1500ms;
}
