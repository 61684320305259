.carouselContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: rgba(6, 18, 68, 1);
  padding: 79px 0;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../../public/Assets/Modules/Home/FAQ/faqback.svg');
  background-size: cover;
  background-repeat: no-repeat;

}

.contentWrapper {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 1225px;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 40px;
}

.title {
  color: rgba(191, 191, 191, 1);
  font-size: 32px;
  font-weight: 410;
  line-height: 52px;
  letter-spacing: 0.48px;
  font-family: 'Sequel Sans', sans-serif;
  margin: 0;
}

.description {
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-weight: 415;
  /* line-height: 46px; */
  letter-spacing: -0.32px;
  margin: 26px 0;
  max-width: 800px;
}

.exploreButton {
  border: none;
  border-radius: 100px;
  background-color: rgba(255, 255, 255, 1);
  padding: 13px 20px;
  font-size: 16px;
  color: rgba(18, 37, 119, 1);
  font-weight: 550;
  letter-spacing: 0.16px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 6px;
}

.exploreButton:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.carouselTrack {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1252px;
  height: 494px;
  perspective: 1000px;
  cursor: grab;
}

.carouselTrack:active {
  cursor: grabbing;
}

.carouselCard {
  position: absolute;
  width: calc(50% - 40px);
  height: 70%;
  transition: transform 0.5s ease, opacity 0.5s ease;
  border-radius: 20px;
  transform-style: preserve-3d;
  will-change: transform, opacity;
}

.carouselCard img {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
}

.carouselCardContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 24px;
  gap: 16px;
  user-select: none;
}

.iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.titleStyle {
  color: #fff;
}

.cardDescription {
  font-size: 20px;
  line-height: 32px;
  color: #fff;
}

.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 8px;
  margin-left: 8px;
}

.backgroundImage {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  background-color: red;
}

.foregroundImage {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.dotsContainer {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
  z-index: 10;
}

.dot {
  width: 12px;
  height: 12px;
  border: none;
  border-radius: 50%;
  background-color: #bdbdbd;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0;
}

.dot:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.dot.active {
  background-color: #fff;
}

.dot:focus {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline-offset: 2px;
}

@media (max-width: 991px) {
  .carouselCard {
    /* width: calc(50% - 40px); */
    flex: 0 0 calc(50% - 140px);
    max-width: calc(50%);
    margin: 0 70px;
    scale: 1.5
  }

  .contentWrapper {
    max-width: 100%;
  }

  .description {
    font-size: 28px;
    line-height: 40px;
  }
}

@media (max-width: 640px) {
  .carouselContainer {
    padding-top: 34px;
  }

  .carouselCard {
    width: calc(100% - 40px);
  }

  .exploreButton {
    font-size: 14px;
    letter-spacing: 0.14px;
    margin-top: 16px;
  }

  .carouselTrack {
    height: 300px;
  }
}

/* @media (prefers-reduced-motion: reduce) {

  .carouselTrack,
  .carouselCard,
  .exploreButton {
    transition-duration: 0.1s;
  }
} */