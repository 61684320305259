.card {
  /* width: 30%; */
  width: 382px;
  height: auto;
  border-radius: 30px;
  margin-bottom: 2rem;
}

.card_body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.card_title {
  color: #272727;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.6px;
  font-weight: bold;
}

.content {
  color: #5a5a5a;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
}

.card_img_top {
  width: auto;
  height: 100px;
  margin: 1rem auto;
}

.card_btn {
  display: flex;
  margin-top: 20px;
  width: auto;
  height: 23px;
  align-items: center;
  justify-content: center;
  color: #1a73e8;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.card_btn > * {
  margin-left: 10px;
}

.card p {
  margin-bottom: 1rem;
  text-align: center;
  /* font-size: 1.2rem; */
  font-size: 1rem;
}

@media (min-width: 1500px) {
  .card_title {
    font-size: 1.5rem;
  }
  .content {
    font-size: 1.3rem;
  }

  .card_btn {
    font-size: 1.3rem;
  }
}

@media (max-width: 550px) {
  .card_title {
    font-size: 18px;
  }

  .content {
    font-size: 14px;
  }

  .card_btn {
    font-size: 14px;
  }
}
