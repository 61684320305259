.appSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0;
    background-color: #061244;
    max-height: 200vh;
}

.content {
    display: flex;
    flex-direction: column;
    width: 40%;
    color: white;
    height: auto;
    align-items: flex-start;
    justify-content: center;
    margin-left: 200px;
}

.title {
    color: #5978FF;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 53px;
}

.subtitle {
    color: #CFCFCF;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px;
}

.description {
    font-size: 1rem;
    color: #CDCDCD;
    font-size: 24px;
    font-style: normal;
    font-weight: 410;
    line-height: 53px;
    margin-bottom: 1rem;
}

.svgImage {
    width: 450px;
    margin-bottom: 1rem;
}

.playstoreLink {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 1rem;
}

.playstoreIcon {
    width: 200px;
    margin-right: 0.5rem;
}

.backgroundImage {
    background-image: url('../../../public/Assets/Modules/Home/mobileapp.svg');
    background-size: cover;
    width: 50%;
    height: 100vh;
    background-color: #061244;
}

@media (max-width: 768px) {
    .appSection {
        flex-direction: column;
        height: auto;
    }

    .content {
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;
        margin-left: 0;
        align-self: flex-start;
        text-align: start;
        padding: 2rem ;
    }

    .title {
        font-size: 12px;
        line-height: 23px;
    }

    .subtitle {
        font-size: 24px;
        line-height: 23px;
    }

    .description {
        font-size: 14px;
    }

    .backgroundImage {
        width: 100%;
        height: 50vh;

        /* background-size: cover; */
        /* background-position: left; */
    }

    .svgImage {
        width: 280px;
    }

    .playstoreIcon {
        width: 150px;
    }
}