.terms {
  display: flex;
  align-items: center;
  gap: 10px;
}

.check {
  margin: 0;
}

.rememberLabel {
  margin: 0;
  color: #414040;
}

.footerText {
  margin: 0;
  margin-left: auto;
}

.forgotStyle{
  text-decoration: none;
  color: #122577;
  font-weight: bold;
}

.eyeStyle {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.passwordStyle {
  width: 100%;
  height: 46px;
  gap: 8px;
  position: relative;
  margin-bottom: 14px;
}

.errorInput {
  border: 1px solid red;
}

.errorText {
  color: red;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 8px;
}

.errorContainer {
  height: 16px;
  margin-bottom: 8px;
}

.eyeIcon {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: -30px;
  padding: 0;
}