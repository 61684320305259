
.section_top4 {
  margin-top: 10rem;
  background-color: rgb(114, 35, 145, 20%);
}

.section_top2,
.section_top8 {
  background-color: rgb(114, 35, 145, 20%);
}

.section_heading_color {
  color: #21113D;
}

.section_top9 {
  width: 100%;
  height: 65%;
  background: rgba(114, 35, 145, 0.4);
}

.section_data9 {
  position: relative;
  top: 130px;
  width: 100%;
  padding: 5rem 7rem 3rem 7rem;
  height: auto;
  box-shadow: 0px 0px 0px 0px #fff;
  background-color: #722391;
  border-radius: 30px;
  text-align: center;
}

.section_data9 h3 {
  font-weight: bold;
  font-size: 2.5rem;
  color: #ff6b00;
}

.section_data9 h3 span {
  color: #fff;
}

.section_data9 p {
  color: #fff;
  text-align: center;
  margin-top: 1.2rem;
  font-size: 1.2rem;
}

.section_btn9 {
  margin-top: 2rem;
  width: 209.015px;
  padding: 20px 40px;
  color: #000;
  font-weight: 600;
  border-radius: 30px;
  border: 1px solid #ff6b00;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.2);
}

.section_top8_img {
  margin-top: -3rem;
}

@media (max-width: 1024px) {
  .section_top9 {
    height: 25%;
  }
}

@media (max-width: 768px) {
  .section_top3 h3 {
    font-size: 22px;
  }

  .section_top3 h3,
  .section_top4 h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 576px) {
  .section_btn9 {
    margin-top: 1rem;
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
  }

  .section_data9 {
    padding: 10px;
  }

  .section_data9 h3 {
    width: 100%;
  }
    
  .section_data9 p {
    color: #fff;
    text-align: center;
    margin-top: 1.2rem;
    font-size: 1.2rem;
  }
}
