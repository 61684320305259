.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.modalContent {
  background: #fff;
  border-radius: 24px;
  position: relative;
  width: auto;
  height: auto;
  /* padding: 10px; */
}

.modalClose {
  position: absolute;
  right: 10px;
  font-size: 50px;
  cursor: pointer;
  padding: 10px;
}
