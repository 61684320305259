.aboutOpportunities {
  margin-top: -2rem;
}

.opportunity_section_even {
  display: flex;
  flex-direction: row-reverse;
}

.opportunity_section {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pet_image {
  margin-top: 50px;
  /* width: 440px;
  height: 420px; */
}

.opportunity_right_section {
  position: relative;
  top: 20px;
  width: 100%;
  padding: 30px;
  gap: 24px;
  height: auto;
  border-radius: 8px;
  background: rgba(248, 249, 255, 0.8);
  box-shadow: 34.854px 29.626px 48.34px 0px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(17.5px);
}

.left {
  left: -40px;
}

.right {
  right: -40px;
}

.opportunity_right_section_info {
  margin-bottom: 1rem;
}

.info_top {
  display: flex;
  align-items: center;
}

.heading {
  color: #272727;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}

.icon {
  width: 32px;
  height: 32px;
  margin-right: 1rem;
}

.content {
  font-size: 1rem;
  color: #5a5a5a;
  text-align: left;
  font-weight: 400;
  padding-left: 46px;
}

@media (min-width:1500px){

  .heading {
    font-size: 1.5rem;
  }
  .content {
    font-size: 1.3rem;
  }
}

@media (max-width: 1000px) {
  .opportunity_section {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: -50px;
  }

  .opportunity_right_section {
    position: relative;
    top: -120px;
    width: 100%;
    padding: 20px 40px;
    height: auto;
    border-radius: 8px;
    background: rgba(248, 249, 255, 0.8);
    box-shadow: 34.854px 29.626px 48.34px 0px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(17.5px);
  }

  .left {
    left: 0px;
  }
  .right {
    right: 0px;
  }
}

@media (max-width: 600px) {
  .opportunity_section {
    margin-bottom: -50px;
  }

  .pet_image {
    margin-top: 50px;
    width: 80%;
    height: 80%;
  }
  .opportunity_right_section {
    padding: 20px 15px;
    top: -60px;
  }
  .heading {
    font-size: 1rem;
  }

  .content {
    font-size: 0.8rem;
    color: #5a5a5a;
    text-align: left;
    font-weight: 400;
    padding-left: 46px;
  }
}
