.container {
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  font-family: Sequel Sans, sans-serif;
  margin: 0 auto;
}

.errorMessage {
  color: #d32f2f;
  font-size: 0.875rem;
  margin-top: 4px;
}

.submitButton {
  width: 100%;
  margin-top: 44px;
  padding: 12px 16px;
  border-radius: 100px;
  background-color: rgb(18, 37, 119);
  color: #fff;
  font-size: 16px;
  font-weight: 415;
  letter-spacing: 0.48px;
  border: none;
  cursor: pointer;
}