.imageView{
    width: 25px;
    height:25px;
    margin-bottom: 8px;
}

.stepIndicator{
    font-size: 1.1rem;
    color: #122577;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 20px;
}

.title{
    font-weight: 600;
}

.check{
  accent-color: #122577;
  transform: scale(1.5);
}

.actionButton {
  display: flex;
  margin-top: 44px;
  width: 100%;
  flex-direction: column;
  color: var(--Basic-White, #fff);
  white-space: nowrap;
  letter-spacing: 0.48px;
  justify-content: start;
  font: 415 16px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
.nextButton {
  align-self: stretch;
  border-radius: 100px;
  background-color: rgba(18, 37, 119, 1);
  min-height: 48px;
  width: 100%;
  gap: 8px;
  overflow: hidden;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  color: inherit;
}

.details{
    margin-bottom: 40px;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}