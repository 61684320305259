.coverPage {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2%;
  width: 100%;
  height: 101vh;
  gap: 7rem;
}

.coverPageContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  gap: 8rem;
  /* margin: 2rem; */
}

.logoDiv {
  display: flex;
  justify-content: center;
}

.logo {
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.coverPageTextDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 1rem;
  margin-top: 2rem;
}

.scrollDown {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.coverPageText {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1rem 5%;
}

.gif {
  height: 60%;
  position: absolute;
  top: -7rem;
}

@media (min-width: 1500px) {
  .coverPageText {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .gif {
    height: 50%;
  }
  .coverPageText {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 590px) {
  .gif {
    height: 40%;
  }

  .coverPageText {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
