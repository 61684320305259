.cont {
  /* width: 100vw; */
  height: auto;
  display: flex;
  padding: 100px;
  align-items: center;
  position: relative;
}

.cont__left {
  width: 40%;
  height: 100vh;
  display: flex;
  align-items: center;
  /* border: 1px solid; */
  margin-left: 7rem;
}

.cont__left__inside {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  /* border: 1px solid; */
  margin-bottom: 5rem;
}

.cont__left__inside span {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;
  gap: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.4px;
  border-radius: 4px;
}

.cont__left__inside h1 {
  color: #00183d;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cont__left__inside p {
  width: 80%;
  color: #5a5a5a;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 3rem;
}

.cont__right {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-left: 60px;
  width: auto;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* border: 1px solid; */
}

.cont__right__img {
  width: auto;
  height: 100%;
}

@media (max-width: 1800px) {
  .cont__left {
    margin-left: 6rem;
    width: 45%;
  }

  .cont__right {
    right: -7%;
  }
}

@media (max-width: 1600px) {
  .cont__left {
    margin-left: 4rem;
    width: 50%;
  }

  .cont__right {
    right: -15%;
  }
}

@media (max-width: 1520px) {
  .cont__left__inside h1 {
    font-size: 57px;
  }
}

@media (max-width: 1450px) {
  .cont__left {
    margin-left: 2rem;
  }
}

@media (max-width: 1390px) {
  .cont__left {
    margin-left: 0;
  }

  .cont__right {
    right: -19%;
  }
}

@media (max-width: 1280px) {
  .cont {
    padding: 50px;
    padding-top: 80px;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .cont__left {
    margin-top: 5rem;
    width: 90%;
    height: auto;
  }

  .cont__left__inside {
    font-size: 22px;
    margin-bottom: 2rem;
  }

  .cont__right {
    position: unset;
    width: 90%;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-left: 0;
  }

  .cont__right img {
    height: 30%;
    width: 100%;
    transform: scale(1.3);
  }
}

@media (max-width: 900px) {
  .cont__left {
    width: 100%;
  }

  .cont__right {
    width: 100%;
  }

  .cont__right img {
    width: 100%;
    height: auto;
  }
}

/* @media (max-width: 780px) {
    .cont {
        padding-left: 40px;
        padding-right: 40px;
    }

} */

/* @media (max-width: 650px) {
    .cont {
        padding: 50px
        padding
    }
} */

@media (max-width: 550px) {
  .cont {
    padding: 30px 20px;
    padding-top: 60px;
  }

  .cont__left {
    width: 100%;
    margin-top: 2rem;
  }

  .cont__left__inside {
    margin-top: 18rem;
    margin-bottom: 1rem;
  }

  .cont__left__inside h1 {
    font-size: 30px;
    margin-top: 0.5rem;
  }

  .cont__left__inside p {
    font-size: 15px;
    margin-bottom: 1rem;
  }

  .cont__left__inside span {
    display: flex;
    padding: 4px 8px;
    align-items: flex-start;
    gap: 10px;
    /* chnaged font */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.4px;
    border-radius: 4px;
  }

  .cont__right {
    position: relative;
  }

  .cont__right img {
    transform: scale(1.3);
    position: absolute;
    right: 129px;
    top: 1rem;
    min-width: 30rem;
  }
}

@media (max-width:406px) {
    .cont__right img {
        transform: scale(1.3);
        position: absolute;
        right: 110px;
        top: 1rem;
        min-width: 30rem;
      }
}