.otpContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.otpInput {
    width: 22%;
    height: 46px;
    font-size: 1.3rem;
    text-align: center;
    border-radius: 4px;
    background: #F2F2F2;
}