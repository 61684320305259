.section_top2,
.section_top4,
.section_top8 {
  background-image: linear-gradient(
    180deg,
    #fbefc6 0%,
    rgba(251, 239, 198, 0.2) 114.4%
  );
}

.section_heading_color {
  color: #3e1b03;
}

.section_top8_img {
  margin-top: -1rem;
}

@media (max-width: 768px) {
  .section_top3 h3 {
    font-size: 22px;
  }
}

@media (max-width: 576px) {
  .section_top2 {
    margin-top: 0rem;
  }

  .section_top8 {
    padding-top: 40px;
    margin-bottom: 0rem;
  }
}
