.form {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  font-family: Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
}

.title {
  color: rgb(18, 37, 119);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
}

.addMore {
  color: rgb(18, 37, 119);
  font-size: 14px;
  font-weight: 450;
  line-height: 1;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline ;
}

.inputGroup {
  margin-bottom: 12px;
}

.label {
  color: rgb(4, 27, 45);
  font-size: 14px;
  font-weight: 405;
  line-height: 1;
  letter-spacing: 0.28px;
  display: block;
  margin-bottom: 6px;
}

.required {
  color: rgb(220, 38, 38);
  margin-left: 2px;
}

.input {
  width: 100%;
  padding: 10px 14px;
  border-radius: 8px;
  border: 1px solid #eee;
  font-size: 16px;
  font-weight: 405;
  letter-spacing: 0.32px;
  color: rgb(4, 27, 45);
  background: #fff;
}

.documentPreview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 24px 4px 4px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid rgb(241, 241, 241);
  box-shadow: 4px 4px 12px rgba(67, 24, 255, 0.06);
}

.fileInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.thumbnail {
  width: 52px;
  height: 52px;
  border-radius: 10px;
  object-fit: contain;
}

.fileDetails {
  display: flex;
  flex-direction: column;
}

.fileName {
  color: rgb(41, 45, 50);
  font-size: 16px;
  font-weight: 500;
}

.fileSize {
  color: rgba(39, 48, 67, 0.5);
  font-size: 12px;
  margin-top: 8px;
}

.actions {
  display: flex;
  gap: 16px;
}

.actionButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.actionIcon {
  width: 24px;
  height: 24px;
}

.uploaderContainer {
  margin-bottom: 12px;
}

.dropzone {
  position: relative;
  border-radius: 8px;
  background-color: rgb(237, 241, 255);
  border: 1px dashed rgba(18, 37, 119, 0.17);
  padding: 16px 30px;
  text-align: center;
  cursor: pointer;
}

.fileInput {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.uploadIcon {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  margin-bottom: 12px;
}

.uploadContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadText {
  color: rgb(13, 13, 13);
  font-size: 14px;
}

.browseLink {
  font-weight: 550;
  text-decoration: underline;
  color: rgb(18, 37, 119);
  cursor: pointer;
}

.uploadHint {
  color: rgb(128, 128, 128);
  font-size: 12px;
  margin-top: 6px;
}

.submitButton {
  width: 100%;
  margin-top: 44px;
  padding: 12px 16px;
  border-radius: 100px;
  background-color: rgb(18, 37, 119);
  color: #fff;
  font-size: 16px;
  font-weight: 415;
  letter-spacing: 0.48px;
  border: none;
  cursor: pointer;
}

.submitButton:hover {
  background-color: rgb(14, 29, 94);
}

.submitButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgb(18, 37, 119, 0.3);
}

.errorText {
    color: red;
    font-size: 0.8em;
    margin-top: 5px;
}