.container {
  display: flex;
  max-width: 441px;
  flex-direction: column;
  overflow: hidden;
  padding: 12px; ;
}
.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}
.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Sequel Sans, sans-serif;
  justify-content: start;
}
.logo {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
  border-radius: 8px;
}
.titleWrapper {
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}
.title {
  color: rgba(10, 10, 10, 1);
  font-size: 24px;
  font-weight: 420;
  line-height: 1;
}
.subtitle {
  color: rgba(73, 72, 72, 1);
  font-size: 16px;
  font-weight: 410;
  line-height: 22px;
  letter-spacing: 0.64px;
  margin-top: 12px;
}
.formWrapper {
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}

.inputLabel {
  color: rgba(4, 27, 45, 1);
  letter-spacing: 0.28px;
  font: 405 14px/1 Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}
.requiredMark {
  color: rgba(220,38,38,1);
}

.input{
     width: 100%;
  padding: 0.7rem;
  margin-bottom: 1rem;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  font-size: 1rem;
}

.validationList {
  display: flex;
  margin-top: 12px;
  width: 360px;
  max-width: 100%;
  flex-direction: column;
  gap: 4px;
}
.validationItem {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px 0;
}
.validationIcon {
  width: 16px;
  height: 16px;
  margin: 4px 4px 4px 0;
}
.validationText {
  flex: 1;
  font: 405 14px Sequel Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.validationTextIdle {
  color: #8f9294;

}

.validationError {
  color: rgba(220, 38, 38, 1);
}
.validationSuccess {
  color: rgba(5, 150, 105, 1);
}
.footer {
  display: flex;
  margin-top: 44px;
  width: 100%;
  flex-direction: column;
  font: 16px Sequel Sans, sans-serif;
}
.submitButton {
  border-radius: 100px;
  background-color: rgba(18, 37, 119, 1);
  min-height: 48px;
  width: 100%;
  color: var(--Basic-White, #fff);
  font-weight: 415;
  letter-spacing: 0.48px;
  padding: 12px 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.submitButton:hover {
  background-color: rgba(18, 37, 119, 0.9);
}
.submitButton:disabled {
  background-color: rgba(18, 37, 119, 0.5);
  cursor: not-allowed;
}
.loginPrompt {
  display: flex;
  margin-top: 16px;
  align-items: center;
  gap: 8px;
  justify-content: center;
}
.loginText {
  color: rgba(4, 27, 45, 1);
  font-weight: 405;
  line-height: 30px;
}
.loginLink {
  color: rgba(18, 37, 119, 1);
  font-weight: 550;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease;
}
.loginLink:hover {
  color: rgba(18, 37, 119, 0.8);
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}