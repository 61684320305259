.aboutUs_page {
  padding: 2rem 8vw;
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
  padding: 2rem 0rem;
}

.section_data_list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.image_section {
  width: 50%;
}

.section_img {
  width: 100%;
  min-width: 22rem;
}

.section_data {
  text-align: left;
  margin-bottom: 1rem;
}

.heading {
  font-weight: 600;
  font-size: 2rem;
  color: #00183d;
}

.content {
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 1500px) {
  .heading {
    font-size: 2.2vw;
  }
  .content {
    font-size: 1.4vw;
  }
}

@media (max-width: 1080px) {
  .aboutUs_page {
    padding: 2rem 4vw;
  }

  .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem 0rem;
  }
}

@media (max-width: 900px) {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem 0rem;
  }

  .section2 {
    display: flex;
    flex-direction: column-reverse;
  }

  .section_data_list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 0rem 8%;
    text-align: center;
  }

  .image_section {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .aboutUs_page {
    padding: 2rem 2rem;
  }
  .section_data_list {
    padding: 0rem;
  }
  .image_section {
    width: 100%;
  }
  .section_img {
    width: 100%;
    min-width: 0rem;
  }
}

@media (max-width: 550px) {
  .heading {
    font-size: 24px;
  }

  .content {
    font-size: 14px;
  }
}
