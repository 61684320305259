.topSection {
  /* background-image: url("/Assets/MainPage/IntroPageImg/BG IMAGES.webp"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 100svh;
  /* align-items: center; */
  justify-content: center;
  position: relative;
}

.topSection::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: #000931;
}

.topSectionContent {
  /* margin-top: 3rem; */
  display: flex;
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5vh;
  z-index: 10;
}

.topLogo {
  fill: #fff;
  filter: drop-shadow(0px 0px 20px rgba(83, 89, 255, 1));
}

.introTagline {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px rgba(83, 89, 255, 0.5);
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 600;
}

.introText {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px rgba(83, 89, 255, 0.5);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.getStartedBtn {
  width: 312px;
  display: flex;
  padding: 1.5%;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: #165dc9;
  box-shadow: 0px 0px 20px 0px rgba(104, 180, 255, 0.25);
  color: #fff;
  font-size: clamp(14px, 5vw, 20px);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
/* 
.getStartedBtn:hover {
  font-weight: bold;
} */

.socialmediaLinks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px;
  width: 32px;
  position: absolute;
  left: 2%;
  height: 100%;
}

.topSocialmediaIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.topSocialmediaIcon:hover {
  filter: drop-shadow(10px 10px 20px rgba(83, 89, 255, 0.9));
  cursor: pointer;
  transform: scale(1.1);
}

@media (min-width: 1500px) {
  .topLogo {
    transform: scale(1.3);
  }

  .introTagline {
    font-size: clamp(2rem, 9vw, 5rem);
    line-height: 6vw; /* 33.735px */
  }

  .introText {
    font-size: clamp(14px, 3.7vw, 26px);
    line-height: 3.2vh;
  }

  .topSocialmediaIcon {
    transform: scale(1.2) rotate(90deg);
  }

  .socialmediaLinks {
    gap: 55px;
    transform: scale(1.2);
  }

  .getStartedBtn {
    font-size: clamp(14px, 5vw, 25px);
    padding: 1.5% 2.5%;
  }
}

@media (max-width: 768px) {
  .topSectionContent {
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5vh;
    z-index: 10;
    margin-top: 12vh;
  }

  .topLogo {
    display: none;
    fill: #fff;
    filter: drop-shadow(0px 0px 20px rgba(83, 89, 255, 0.5));
  }

  .introTagline {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 20px rgba(83, 89, 255, 0.5);
    font-size: clamp(2rem, 9vw, 6rem);
    font-style: normal;
    font-weight: 600;
    align-self: stretch;
    line-height: 12vw; /* 33.735px */
  }

  .introText {
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 20px rgba(83, 89, 255, 0.5);
    font-size: clamp(14px, 3.7vw, 18px);
    font-style: normal;
    font-weight: 400;
    line-height: 3.2vh;
  }

  .socialmediaLinks {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 35px;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 5%;
    left: 0;
    right: 0;
  }

  .topSocialmediaIcon {
    width: 38px;
    height: 38px;
    cursor: pointer;
    transform: scale(1.7) rotate(90deg);
    /* transition: scale(2); */
  }
}

@media (max-width: 550px) {
  .topSectionContent {
    margin-top: 7rem;
  }

  .topSocialmediaIcon {
    width: 38px;
    height: 38px;
    cursor: pointer;
    transform: scale(1.3) rotate(90deg);
  }
  .getStartedBtn {
    width: 100%;
    padding: 3%;
  }
  .socialmediaLinks {
    gap: 20px;
  }
}
