.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  background-color: #f9f9f9;
  /* margin-top: 100px; */
}

.imageSection {
  flex: 1;
  position: relative;
  display: none; /* Hidden on small screens */
  overflow: hidden;
  margin-top: 20px;
}

.overlayImage{
  position: relative;
  bottom: 30px;
  left: 20px;
  width: 100%;
  height: 100%;
  background-color: red;
  overflow: hidden;
}

.carouselImage {
  display: flex;
  transition: transform 0.5s ease;
  width: 100%;
  height: 100%;
}

.imageStyle {
  /* width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  background-color: green;
  margin: 0; */
   flex: 0 0 100%;
    width: 100%;
    object-fit: cover;
}

.overlayText {
  position: absolute;
  bottom: 30px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  color: #fff;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  overflow: hidden;
  /* background-color: #0056b3; */
}
.contentWrapper {
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease;
}

.formSection {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 2rem;
  overflow-y: auto;
}

.signupForm {
  width: 100%;
  max-width: 600px;
  padding: 2rem;
  background: #fff;
}

.input {
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 1rem;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  font-size: 1rem;
}

.errorInput {
  border: 1px solid red;
}

.errorText {
  color: red;
  font-size: 12px;
  /* margin-top: -1rem; */
  margin-bottom: 8px;
}

.mobileError{
 margin-top: 16px;
}

.labelStyle{
  margin-top: 12px;
}

.button {
  width: 100%;
  padding: 0.8rem;
  background-color: #122577;
  color: #fff;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 2rem;
}

.button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.terms {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 2rem;
  color: #666666;
}

.termserror{
  margin-left: 20px;
}

.phoneInput {
  display: flex;
  height: 40px;
  margin-bottom: 1rem;
}

.countryCode {
  flex: 0 0 20%;
  padding: 0.5rem;
  text-align: center;
  border: 1px solid #e4e4e4;
  border-radius: 5px 0 0 5px;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
}

.phoneInput input {
  flex: 1;
  border-radius: 0 5px 5px 0;
  height: 100%;
  box-sizing: border-box;
}

.required {
  color: red;
}

@media (min-width: 768px) {
  .imageSection {
    display: block;
  }
}

.heroContainer {
  border-radius: 0 0 12px 12px;
  background-color: rgba(10, 10, 10, 0.3);
  display: flex;
  max-width: 770px;
  flex-direction: column;
  justify-content: center;
  padding: 42px 42px 32px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 991px) {
  .heroContainer {
    padding: 0 20px;
  }
}

.logo {
  aspect-ratio: 4.61;
  object-fit: contain;
  object-position: center;
  width: 92px;
  align-self: center;
}

.slide {
  flex: 0 0 100%;
  width: 100%;
}

.textContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Sequel Sans, sans-serif;
  text-align: center;
  justify-content: start;
  padding: 20px;
  overflow: hidden;
}

.headingWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  max-width: 90%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
}

.heading {
  color: #fff;
  font-size: 32px;
  font-weight: 420;
  line-height: 38px;
  letter-spacing: 0.32px;
  align-self: center;
  margin: 0;
}

.subheading {
  color: #f0f0f0;
  font-size: 18px;
  font-weight: 410;
  line-height: 25px;
  letter-spacing: 0.18px;
  margin-top: 8px;
}

.dotsWrapper {
  align-self: center;
  display: flex;
  margin-top: 24px;
  width: 402px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 70px;
}

@media (max-width: 991px) {
  .dotsWrapper {
    padding: 0 20px;
  }
}

.dotsContainer {
  border-radius: 50px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  padding: 8px 12px;
}

.dotsPlatter {
  border-radius: 100px;
  align-self: start;
  position: absolute;
  z-index: 0;
  display: flex;
  height: 24px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 112px;
}

.dot {
  border-radius: 50%;
  align-self: stretch;
  z-index: 1;
  display: flex;
  width: 2px;
  height: 14px;
  margin: auto 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  border-color: transparent;
}

.dotActive {
  background-color: #fff;
}

.dotInactive {
  background-color: #8e8887;
}

.infoStyle{
  display: flex;
  color: #8f9294;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.12px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  gap: 0.5rem;
}

.infoStyle p {
  margin-left: 8px;
}

.linkStyle{
  text-decoration: underline;
  color: #666666;
  cursor: pointer;
}

.footerText{ 
  margin-top: 1rem; 
  text-align:center 
}

.footerText a{
  color: #122577;
  font-weight: 600;
}

.check{
  accent-color: #122577;
  transform: scale(1.5);
}

