
.multi_btn:hover {
    /* color: #fff; */
    /* background-color: #FED462; */
}

.buttonSection{
    display: flex;
    gap: 2rem;
    width: 80%;
    height: auto;
}

.discord{
    border: none;
    display: flex;
    min-height: 50px;
    padding: 10px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    min-width: 240px;
    max-width: 300px;
    border-radius: 8px;
/* background: #FED462; */
}

.partner{
    color: black;
    display: flex;
    min-height: 45px;
    min-width: 240px;
    max-width: 300px;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
}

button:hover{
    box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.5);
}

@media (max-width:1000px){
    .buttonSection{
        width: 100%;
    }
}


@media (max-width:552px){
    .buttonSection{
        flex-direction: column;
        width: 100%;
        gap: 1rem;
    }

    
.partner{
    font-size: 14px;
    color: black;
    display: flex;
    min-height: 25px;
    min-width: 240px;
    max-width: 300px;
    padding: 7px 30px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
}
}