.cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 912px;
    height: 560px;
    background: #FFFFFF;
    border-radius: 24px;
}

.contExpand {
    height: 640px;
}

.topLogo {
    width: 118px;
    height: 118px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.topHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 69%;
    height: 110px;
    margin-bottom: 30px;
}


.topHeading__first {
    width: 130%;
    height: 51px;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    color: #00183D;
}

.topHeading__first__reduceFontSize {
    font-size: 36px;
}

.topHeading__second {
    width: 100%;
    height: 51px;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #666666;
}

.entries__field {
    width: 69%;
    height: 77px;
    gap: 12px;
    /* opacity: 0px; */
    display: flex;
    flex-direction: column;
    position: relative;
}

.entries__field__inputVerify {
    width: 110%;
    display: flex;
    position: relative;;
}

.entries__field__expand {
    width: 100%;
    height: 46px;
    border-style: none;
    background: #F2F2F2;
    gap: 8px;
    border-radius: 8px;
    /* opacity: 0px; */
    transition: width 0.5s ease;
    padding-left: 10px;
}

.entries__field__shrink {
    width: 80%;
    height: 46px;
    border-style: none;
    background: #F2F2F2;
    gap: 8px;
    border-radius: 8px;
    /* opacity: 0px; */
    transition: width 0.5s ease;
    padding-left: 10px;
}

.entries__field__verifyButton__hide {
    width: 10%;
    height: 46px;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #1A73E8;
    opacity: 0;
}

.entries__field__verifyButton__show {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    margin-left: 10px;
    height: 46px;
    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #1A73E8;
    opacity: 1px;
    transition: opacity 1s ease;
    cursor: pointer;
}

@keyframes infiniteRotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.entries__field__verificationStage1Icon {
    font-size: 2rem;
    color: #4CAF50;
    animation: infiniteRotation 1s linear infinite;
}

.entries__field__verificationStage2Icon {
    font-size: 2rem;
    color: #4CAF50
}

.entries__field__head {
    width: 100%;
    height: 19px;
    gap: 0px;
    /* opacity: 0px; */
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: left;
}

.entries__field__cover {
    width: 100%;
    height: 46px;
    gap: 8px;
    position: relative;
}

.entries__field__otpHead {
    width: 100%;
    height: 19px;
    gap: 0px;
    /* opacity: 0px; */
    font-family: 'SF Pro Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.entries__field__otpCover {
    width: 100%;
    height: 46px;
    gap: 8px;
}

.entries__field__eyeIcon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    width: 24px;
    height: 24px;
    cursor: pointer;
}


.bottomButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 30px;
    margin-top: 10px;
    gap: 8px;
    width: 69%;
    height: 56px;
    background: #FED462;
    border-radius: 8px;

    font-family: 'SF Pro Display', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 10px;
}

.bold {
    font-weight: 600;
}

.error {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    color: red;
    font-size: 0.9rem;
    height: auto;
    border-radius: 5px;
    width: auto;
    padding: 5px;
}

.errorHeading {
    color: rgba(255, 0, 0, 0.761);
}

@media (max-width: 1000px) {
    .cont {
        width: 800px;
    }
}

@media (max-width: 850px) {
    .cont {
        width: 700px;
    }

    .topHeading__first{
        font-size: 32px;
    }

    .topHeading__first__reduceFontSize {
        font-size: 28px;
    }
}

@media (max-width: 750px) {
    .cont {
        width: 600px;
    }

    .topHeading__first__reduceFontSize {
        font-size: 26px;
    }
}

@media (max-width: 650px) {
    .cont {
        width: 510px;
    }

    .topHeading__first {
        font-size: 28px;
    }

    .topHeading__first__reduceFontSize {
        font-size: 22px;
    }

    .topHeading__second {
        font-size: 16px;
    }
}

@media (max-width: 550px) {
    .cont {
        width: 430px;
    }

    .topHeading__first {
        font-size: 24px;
    }

}

@media (max-width: 450px) {
    .cont {
        width: 440px;
    }

    .topHeading__first {
        font-size: 23px;
    }
    
}

@media (max-width: 442px) {
    .cont {
        width: 430px;
    }

    .entries__field,.bottomButton {
        width: 73%;
    }
}

@media (max-width: 432px) {
    .cont {
        width: 422px;
    }

    .entries__field,.bottomButton {
        width: 75%;
    }
}

@media (max-width: 423px) {
    .cont {
        width: 400px;
    }

    .topHeading__first {
        font-size: 21px;
    }

    .entries__field,.bottomButton {
        width: 77%;
    }
}

@media (max-width: 405px) {
    .cont {
        width: 385px;
    }

    .topHeading__first {
        font-size: 20px;
        width: 140%;
    }

    .entries__field,.bottomButton {
        width: 80%;
    }
}

@media (max-width: 395px) {
    .cont {
        width: 380px;
    }

    .entries__field,.bottomButton {
        width: 83%;
    }
}

@media (max-width: 385px) {
    .cont {
        width: 365px;
    }

    .entries__field,.bottomButton {
        width: 85%;
    }
}

@media (max-width: 370px) {
    .cont {
        width: 350px;
    }

    .entries__field,.bottomButton {
        width: 87%;
    }
}

@media (max-width: 354px) {
    .cont {
        width: 320px;
    }

    .entries__field,.bottomButton {
        width: 90%;
    }
}